import { gql } from '@apollo/client';

export const groupUsersQuery = gql`
  query Query {
    getGroupUsers {
      Attributes {
        Name
        Value
      }
    }
  }
`;
