import { gql } from '@apollo/client';

export const generatePatientDataUploadURL = gql`
  query generatePatientDataUploadURL(
    $generatePatientDataUploadUrlId: ID!
    $arrayMetadata: [FileMetadataInput]!
  ) {
    generatePatientDataUploadURL(id: $generatePatientDataUploadUrlId, arrayMetadata: $arrayMetadata)
  }
`;
