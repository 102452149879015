import { gql } from '@apollo/client';

export const consultationById = gql`
  query consultationById($consultationByIdId: ID!) {
    consultationById(id: $consultationByIdId) {
      consultation_id
      patient_id
      doctor
      event {
        id
        title
        description
        created_by
        last_update_by
        mandatory_presence
        optional_presence
        begin
        end
        created_at
        updated_at
        consultation_id
        state
      }
      notes
      payed_amount
      amount_to_pay
      total_amount
      consultation_description
    }
  }
`;
