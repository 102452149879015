import { useQuery } from '@apollo/client'; // material
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input
} from '@mui/material';
import { getUsersByEmail } from 'graphql/querys/getUsersByEmail';
import { useEffect, useState } from 'react';

export default function UserListTable({ actionTitle, actionHandler }) {
  const [clickedSubs, setClickedSubs] = useState([]);
  const [filterEmail, setFilterEmail] = useState('');

  const [debounceFilterEmail, setDebounceFilterEmail] = useState('');
  useEffect(() => {
    const debounceId = setTimeout(() => {
      setDebounceFilterEmail(filterEmail);
    }, 300);

    return () => {
      clearTimeout(debounceId);
    };
  }, [filterEmail]);

  const {
    data: allUsers,
    loading: allUsersLoading,
    error: allUsersError
  } = useQuery(getUsersByEmail, {
    variables: { emailSearch: debounceFilterEmail }
  });
  const handleFilterByEmail = (event) => {
    setFilterEmail(event.target.value);
  };

  if (allUsersError) return <p>ERROR</p>;
  const getVal = (user, key) => user.Attributes.find((a) => a.Name === key)?.Value;
  const preActionHandler = (sub) => {
    setClickedSubs([...clickedSubs, sub]);
    actionHandler(sub);
  };

  return (
    <>
      <div style={{ padding: '10px' }}>
        Search by email :
        <Input
          placeholder="search"
          sx={{ fontSize: '1.2em' }}
          value={filterEmail}
          onChange={handleFilterByEmail}
        />
        {allUsers && allUsers.getUsersByEmail.length === 60 && (
          <>
            <br />
            <br />
            showing only 60 first results
          </>
        )}
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Family Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Email verified</TableCell>
              {actionTitle && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {allUsers &&
              allUsers.getUsersByEmail.map((user) => (
                <TableRow key={getVal(user, 'sub')}>
                  <TableCell>{getVal(user, 'name')}</TableCell>
                  <TableCell>{getVal(user, 'family_name')}</TableCell>
                  <TableCell>{getVal(user, 'email')}</TableCell>
                  <TableCell>{getVal(user, 'email_verified')}</TableCell>
                  {actionHandler && (
                    <TableCell>
                      {clickedSubs.includes(getVal(user, 'sub')) ? (
                        <p>User added!</p>
                      ) : (
                        <Button
                          variant="outlined"
                          onClick={() => preActionHandler(getVal(user, 'sub'))}
                        >
                          {actionTitle}
                        </Button>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
