import { gql } from '@apollo/client';

export const insertConsultationMutation = gql`
  mutation insertConsultation($consultationData: ConsultationInput!) {
    insertConsultation(consultationData: $consultationData) {
      consultationData {
        event {
          title
          mandatory_presence
          begin
          end
          state
        }
        patient_id
        consultation_id
        notes
        payed_amount
        doctor
        amount_to_pay
        total_amount
        consultation_description
      }
      consultationError {
        field
        message
      }
    }
  }
`;
