// @ts-nocheck
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box
} from '@mui/material';
import Dropzone from '../Form/DropzoneWrapper';
import UserMoreMenu from '../_dashboard/user/UserMoreMenu';

export default function UploadFiles({ setFiles, files }) {
  const handleDelete = (e, name) => {
    setFiles((prevFiles) => {
      const index = prevFiles.findIndex((item) => item.name === name);
      prevFiles.splice(index, 1);
      return [...prevFiles];
    });
  };
  return (
    <Box my={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h4" gutterBottom>
          Ingresar archivos
        </Typography>
      </Stack>
      <Dropzone setFiles={setFiles} />
      {files.length !== 0 && (
        <Box py={2}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Archivos ingresados</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((file) => (
                  <TableRow
                    key={file.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {file.name}
                    </TableCell>
                    <TableCell align="right">
                      <UserMoreMenu handleDelete={(e) => handleDelete(e, file.name)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}
