import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { dataURLtoFile } from '../../../utils/s3Actions';
import { UserMoreMenu } from '../user';

const TABLE_HEAD = [
  // THE id here is used to control the sort value, it has to correspond to the value to be compared
  { id: 'signatureDate', label: 'Fecha', alignRight: false }
];

function formattedDate(d = new Date()) {
  return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('/');
}

export function InsertSignature(props) {
  const signRef = useRef();

  const handleClear = () => {
    signRef.current.clear();
  };

  const handleSave = async () => {
    const dataUrl = signRef.current.getTrimmedCanvas().toDataURL('image/png');
    const file = await dataURLtoFile(dataUrl, 'signature');
    file.date = formattedDate(new Date(Number(file.lastModified)));
    props.setSignatureFile([file]);
    handleClear();
  };

  const handleDeleteSelected = () => {
    props.setSignatureFile([]);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <Typography variant="h4" gutterBottom>
              Ingresar firma
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Box m={3} display="flex" justifyContent="center" alignItems="center">
              <Card>
                <img
                  src="/static/writtenConsentment.png"
                  alt="Your"
                  style={{ width: '450px', height: '500px' }}
                />
                <SignatureCanvas
                  ref={signRef}
                  penColor="#16537e"
                  canvasProps={{
                    width: 200,
                    height: 200,
                    style: {
                      border: '1px solid #ccc' // Gray border, 1px width
                    } // Add a border with the desired width and color
                  }}
                  // onBegin={handleLockUpdate}
                  // onEnd={handleUpdateData}
                />
              </Card>
            </Box>
          </Grid>
          <Grid mb={2} item xs={6} style={{ textAlign: 'right' }}>
            <Box>
              <Button variant="contained" onClick={handleClear}>
                Borrar
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'left' }}>
            <Box>
              <Button variant="contained" onClick={handleSave}>
                Ingresar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Stack>
      <Card sx={{ boxShadow: 3 }}>
        {props.signatureFile.length !== 0 && (
          <Box py={2}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Firma ingresada</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.signatureFile.map((file) => (
                    <TableRow
                      key={file.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {file.date}
                      </TableCell>
                      <TableCell align="right">
                        <UserMoreMenu handleDelete={() => handleDeleteSelected()} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Card>
    </>
  );
}
