import { gql } from '@apollo/client';

export const getUsersByEmail = gql`
  query Query($emailSearch: String!) {
    getUsersByEmail(emailSearch: $emailSearch) {
      Attributes {
        Name
        Value
      }
    }
  }
`;
