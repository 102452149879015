import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Box, Button, Card, Dialog, Skeleton, Stack, Typography } from '@mui/material';
import { filter } from 'lodash';
import { useState } from 'react';
import PropTypes from 'prop-types';

import CreatePayment from '../../../pages/CreatePayment';
import ListToolbar from '../user/ListToolbar';
import OwedConsultationTableContent from './OwedConsultationTableContent';
import { getComparator, revertFormattedDate } from '../../../utils/comparator';

const TABLE_HEAD = [
  // THE id here is used to control the sort value, it has to correspond to the value to be compared
  { id: 'event.begin', label: 'Fecha', alignRight: false },
  { id: 'consultationDescription', label: 'Motivo de la consulta', alignRight: false },
  { id: 'payedAmount', label: 'Monto cancelado', alignRight: false },
  { id: 'amountToPay', label: 'Monto por cancelar', alignRight: false },
  { id: 'totalAmount', label: 'Monto total', alignRight: false }
];

// TODO: refactor to utils/comparator.js
function descendingComparator(a, b, orderBy) {
  const checkDateA = revertFormattedDate(a[orderBy]);
  const checkDateB = revertFormattedDate(b[orderBy]);
  if (checkDateA !== -1 && checkDateB !== -1) {
    // This means that a and b are dates
    if (checkDateB.getTime() < checkDateA.getTime()) {
      console.log('-1');
      return -1;
    }
    if (checkDateB.getTime() > checkDateA.getTime()) {
      console.log('1');
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    console.log('-1');
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    console.log('1');
    return 1;
  }
  return 0;
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1]; // this allows us to keep original order in case of similar order values 'A LITTLE OVERKILL'
  });
  //  Pass query as parameter for front side search
  if (query) {
    return filter(
      array,
      (_consultation) =>
        `${_consultation.consultationDescription.toLowerCase()}`.indexOf(query.toLowerCase()) !==
          -1 || `${_consultation.event.begin}`.indexOf(query) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

OwedConsultations.propTypes = {
  owedConsultations: PropTypes.array
};

export function OwedConsultations(props) {
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('lastName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { owedConsultations } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!owedConsultations) {
    return <Skeleton animation="wave" />;
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - owedConsultations.length) : 0;

  const filteredConsultations = applySortFilter(
    owedConsultations,
    getComparator(order, orderBy),
    filterName
  );

  const isConsultationNotFound = filteredConsultations.length === 0;
  return (
    <>
      <Box mt={2}>
        <Card sx={{ boxShadow: 3 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
            pt={3}
            px={3}
          >
            <Typography variant="h5">Consultas por pagar</Typography>
            <Button
              variant="contained"
              onClick={handleClickOpen}
              startIcon={<Icon icon={plusFill} />}
            >
              Ingresar pago
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
              <CreatePayment toClose={handleClose} />
            </Dialog>
          </Stack>
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder="Buscar consulta..."
          />
          <OwedConsultationTableContent
            filterName={filterName}
            handleFilterByName={handleFilterByName}
            handleRequestSort={handleRequestSort}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            order={order}
            orderBy={orderBy}
            TABLE_HEAD={TABLE_HEAD}
            filteredConsultations={filteredConsultations}
            page={page}
            rowsPerPage={rowsPerPage}
            emptyRows={emptyRows}
            isConsultationNotFound={isConsultationNotFound}
          />
        </Card>
      </Box>
    </>
  );
}
