import { gql } from '@apollo/client';

export const DeleteConsultations = gql`
  mutation deleteConsultations($patientId: ID!, $ids: [ID]!) {
    deleteConsultations(patientId: $patientId, ids: $ids) {
      paymentData {
        payed_amount
      }
      paymentError {
        field
        message
        totalDebt
      }
    }
  }
`;
