import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
// material
import { Container, Skeleton, Box } from '@mui/material';
// components
import Page from '../components/Page';
import { UserDetailHeader } from '../components/_dashboard/userdetail/UserDetailHeader';
import { UserDetailBody } from '../components/_dashboard/userdetail/UserDetailBody';
//
import { PatientDetailById } from '../graphql/querys/patientDetailByIdQuery';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' }
];

// ----------------------------------------------------------------------

export default function UserDetail(props) {
  const { id } = useParams();
  const { data: pData, loading: patientLoading } = useQuery(PatientDetailById, {
    variables: { id }
  });

  if (patientLoading) {
    return (
      <Page title="Dental by Aronin">
        <Container>
          <Skeleton />
          <Skeleton variant="rectangular" height={300} />
        </Container>
        <br />
        <Container>
          <Skeleton variant="rectangular" height={300} />
        </Container>
      </Page>
    );
  }
  const p = pData.patientById;
  return (
    <Page title="Dental by Aronin">
      <Box pb={4}>
        <Container>
          <UserDetailHeader patient={p} />
        </Container>
      </Box>
      <br />
      <Container>
        <UserDetailBody default={props.default} />
      </Container>
    </Page>
  );
}
