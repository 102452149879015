import { gql } from '@apollo/client';

export const deletePatientsMutation = gql`
  mutation DeletePatientsById($patientIds: [ID]!) {
    deletePatientsById(patientIds: $patientIds) {
      patientDeletionData
      PatientDeletionError {
        field
        message
      }
    }
  }
`;
