/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
/* eslint-disable no-empty */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import { Form } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import {
  Autocomplete,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import ToolTipWrapper from 'components/Form/ToolTipWrapper';
import { withSubForm } from './nestedForms';

// TODO: delete this ???
const eventSchema = Yup.object().shape({
  consultationDate: Yup.string(),
  startHour: Yup.string(),
  endHour: Yup.string(),
  state: Yup.string()
});

function generateVisualHours() {
  const hours = [...Array(12).keys()];
  const minutes = [0, 15, 30, 45];
  const output = [];
  hours.forEach((h) =>
    minutes.forEach((m) => {
      output.push(`${h.toString().padStart(1, '0')}:${m.toString().padStart(2, '0')} am`);
    })
  );
  hours.forEach((h) =>
    minutes.forEach((m) => {
      output.push(`${h.toString().padStart(1, '0')}:${m.toString().padStart(2, '0')} pm`);
    })
  );
  return output;
}

function processDateFromVisualHours(date, visualHours) {
  const output = new Date();
  try {
    const hoursPart = visualHours.split(' ')[0];
    const pmAmPart = visualHours.split(' ')[1];
    const day = date.split('-')[2];
    const month = date.split('-')[1];
    const year = date.split('-')[0];
    const hours = parseInt(hoursPart.split(':')[0], 10);
    const minutes = parseInt(hoursPart.split(':')[1], 10);
    const hoursOffset = pmAmPart === 'pm' ? 12 : 0;
    output.setHours(hours + hoursOffset); // hours above 24 are automatically added to days
    output.setMinutes(minutes);
    output.setDate(day);
    output.setMonth(month - 1);
    output.setFullYear(year);
  } catch (e) {
    console.log(e);
  }
  return output;
}

function processVisualHourFromDate(date) {
  const hours = (date.getHours() % 12).toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const out = `${hours}:${minutes} ${date.getHours() > 12 ? 'pm' : 'am'}`;
  return out;
}

const EventForm = ({ name, values, errors, setFieldValue, touched, ...props }) => {
  console.log('aa', props);
  if (!values.begin) {
    values.begin = new Date();
  }
  if (!values.end) {
    values.end = new Date();
  }
  const begin = new Date(values.begin);
  const end = new Date(values.end);
  const monthWithZeros = (begin.getMonth() + 1).toString().padStart(2, '0');
  const dayWithZeros = begin.getDate().toString().padStart(2, '0');

  const [currentBeginDate, setCurrentBeginDate] = React.useState(
    `${begin.getFullYear()}-${monthWithZeros}-${dayWithZeros}`
  );
  const [currentBeginHour, setCurrentBeginHour] = React.useState(processVisualHourFromDate(begin));
  const [currentEndHour, setCurrentEndHour] = React.useState(processVisualHourFromDate(end));
  const [currentState, setCurrentState] = React.useState(values.state || 'scheduled');

  const handleBeginDateChange = (e) => {
    setCurrentBeginDate(e.target.value);
  };

  const handleBeginHourChange = (e) => {
    const value = e.target.innerText || e.target.value;
    setCurrentBeginHour(value);
  };

  const handleEndHourChange = (e) => {
    const value = e.target.innerText || e.target.value;
    setCurrentEndHour(value);
  };

  React.useEffect(() => {
    // Reset State to scheduled if date is changed to the future
    if (currentState === 'done' && isConsultationInFuture()) setCurrentState('scheduled');
    // Update the form values
    values.begin = processDateFromVisualHours(currentBeginDate, currentBeginHour).toISOString();
    values.end = processDateFromVisualHours(currentBeginDate, currentEndHour).toISOString();
    values.state = currentState;
  }, [currentBeginDate, currentBeginHour, currentEndHour, currentState, values]);

  const isConsultationInFuture = () => {
    const beginDate = processDateFromVisualHours(currentBeginDate, currentBeginHour);
    if (!beginDate) return true;
    return beginDate > new Date();
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (v) => v.replace(':', '')
  });

  return (
    <Form>
      <Grid container rowSpacing={2} columnSpacing={3}>
        <Grid item xs={6}>
          <ToolTipWrapper
            condition={props.isStateDisabled}
            title="No se puede cambiar la fecha de una consulta si tiene pagos asignados."
          >
            <TextField
              fullWidth
              label="Fecha"
              inputProps={{
                readOnly: props.isDisabled || props.isStateDisabled
              }}
              type="date"
              value={currentBeginDate}
              onChange={handleBeginDateChange}
            />
          </ToolTipWrapper>
        </Grid>
        <Grid item xs={6}>
          <ToolTipWrapper
            condition={props.isStateDisabled}
            title="No se puede cambiar el estado de una consulta si tiene pagos asignados."
          >
            <FormControl fullWidth>
              <TextField
                select
                label="Estado"
                value={currentState}
                inputProps={{
                  readOnly: props.isStateDisabled || props.isDisabled
                }}
                onChange={(e) => setCurrentState(e.target.value)}
              >
                <MenuItem value="scheduled">Agendado</MenuItem>
                <MenuItem disabled={isConsultationInFuture()} value="done">
                  Realizado
                </MenuItem>
              </TextField>
            </FormControl>
          </ToolTipWrapper>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            name="startHour"
            readOnly={props.isDisabled}
            freeSolo
            options={generateVisualHours()}
            filterOptions={filterOptions}
            autoHighlight
            onChange={handleBeginHourChange}
            value={currentBeginHour}
            renderInput={(params) => <TextField {...params} label="Hora de inicio" />}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            name="endHour"
            readOnly={props.isDisabled}
            freeSolo
            options={generateVisualHours()}
            filterOptions={filterOptions}
            autoHighlight
            onChange={handleEndHourChange}
            value={currentEndHour}
            renderInput={(params) => <TextField {...params} label="Hora de finalización" />}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export const EventSubForm = (customProps) => withSubForm(EventForm, eventSchema, customProps);
