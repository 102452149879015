import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client'; // material
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import {
  Alert,
  Button,
  Card,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { removeUserFromGroupMutation } from 'graphql/mutations/removeUserFromGroup';
import { Link as RouterLink, useParams } from 'react-router-dom';
// components
import externalLinkOutline from '@iconify/icons-eva/external-link-outline';
import { specificGroupUsersQuery } from 'graphql/querys/specificGroupUsers';
import Page from 'components/Page';

// ----------------------------------------------------------------------

export default function GroupDetail() {
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const { GroupName } = useParams();

  const { data: usersQuery, loading: usersQueryLoading } = useQuery(specificGroupUsersQuery, {
    variables: { GroupName }
  });

  const [
    removeUserFromGroup,
    {
      data: removeUserFromGroupData,
      loading: removeUserFromGroupLoading,
      error: removeUserFromGroupError
    }
  ] = useMutation(removeUserFromGroupMutation, {
    // Then re-run
    refetchQueries: [{ query: specificGroupUsersQuery, variables: { GroupName } }]
  });

  if (usersQueryLoading || removeUserFromGroupLoading) {
    return (
      <Page title="Dental by Aronin">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Group detail : {GroupName}
            </Typography>
          </Stack>
          <Card sx={{ boxShadow: 3 }}>
            <p>Loading data...</p>
          </Card>
        </Container>
      </Page>
    );
  }
  // const users = usersQuery.users
  const users = usersQuery.getSpecificGroupUsers;
  const getVal = (user, key) => user.Attributes.find((a) => a.Name === key).Value;

  const handleRemoveUserFromGroup = async (sub, GroupName) => {
    await removeUserFromGroup({
      variables: {
        sub,
        GroupName
      }
    });
    if (!removeUserFromGroupError) {
      setConfirmDeletion(true);
      setTimeout(() => {
        setConfirmDeletion(false);
      }, 4000);
    }
  };

  return (
    <Page title="Dental by Aronin">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Group detail : {GroupName}
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="addUser"
            startIcon={<Icon icon={plusFill} />}
          >
            Add user
          </Button>
        </Stack>

        <Card sx={{ boxShadow: 3 }}>
          {confirmDeletion && <Alert severity="success">User has been removed from group !</Alert>}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Family Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Email verified</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={getVal(user, 'sub')}>
                    <TableCell>{getVal(user, 'name')}</TableCell>
                    <TableCell>{getVal(user, 'family_name')}</TableCell>
                    <TableCell>{getVal(user, 'email')}</TableCell>
                    <TableCell>{getVal(user, 'email_verified')}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => handleRemoveUserFromGroup(getVal(user, 'sub'), GroupName)}
                      >
                        Remove from group
                        <Icon icon={externalLinkOutline} width={20} height={20} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </Page>
  );
}
