import { useQuery } from '@apollo/client';
import externalLinkOutline from '@iconify/icons-eva/clock-outline';
import { Icon } from '@iconify/react';
import { Button, Dialog, Skeleton, Stack, Typography } from '@mui/material';

import { Payments } from 'components/payments/Payments';
import { ConsultationsOwedByPatientId } from 'graphql/querys/consultationsOwedByPatientIdQuery';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { fromUTCdatetimeToLocalDate } from 'utils/formatTime';
import { ConsultationsByPatientId } from '../../../graphql/querys/consultationsByPatientIdQuery';
import { toCamelCase } from '../../../utils/toCamelCase';
import { OwedConsultations } from '../consultations/OwedConsultations';
import { PayedConsultations } from '../consultations/PayedConsultations';

export function PaymentHistory() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const { id } = useParams();
  const { data: pData, loading: consultationsLoading } = useQuery(ConsultationsByPatientId, {
    variables: { consultationsByPatientIdId: id }
  });
  const { data: dataConsultationsOwed, loading: dataConsultationsOwedLoading } = useQuery(
    ConsultationsOwedByPatientId,
    {
      variables: { id }
    }
  );

  if (consultationsLoading || dataConsultationsOwedLoading || !pData) {
    return <Skeleton />;
  }
  const consultations = toCamelCase(pData.consultationsByPatientId);
  const owedConsultations = toCamelCase(dataConsultationsOwed.consultationsOwedByPatientId);
  owedConsultations.forEach((oc) => (oc.event.begin = fromUTCdatetimeToLocalDate(oc.event.begin)));
  consultations.forEach((c) => (c.event.begin = fromUTCdatetimeToLocalDate(c.event.begin)));
  const payedConsultations = consultations.filter((consultation) => consultation.amountToPay === 0);
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
        pt={3}
        px={3}
      >
        <Typography variant="h4">Estado de cuenta</Typography>
        <Button variant="outlined" onClick={handleClickOpen}>
          Historial de pago
          <Icon icon={externalLinkOutline} width={20} height={20} style={{ margin: '2px' }} />
        </Button>
        <Dialog
          PaperProps={{
            sx: {
              width: '100%',
              height: { xs: '80%', md: '100%' },
              margin: { xs: '0', md: '30px' }
            }
          }}
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="lg"
        >
          <Payments toClose={handleClose} />
        </Dialog>
      </Stack>
      <OwedConsultations owedConsultations={owedConsultations} />
      <PayedConsultations payedConsultations={payedConsultations} />
    </>
  );
}
