// @ts-nocheck
import { deletePatientsMutation } from 'graphql/mutations/deletePatients';
import { patientByDetailQuery } from 'graphql/querys/patientByDetailQuery';
import { patientsByEnum } from 'graphql/querys/patientsByEnum';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// components
import { dateFormater } from '../../../utils/dateFormater';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import UserListHead from './UserListHead';
import ContextPatientMenu from './ContextPatientMenu';
//
import { patientsCount } from '../../../graphql/querys/patientsCount';

// eslint-disable-next-line react/prop-types
const FirstConfirmDialog = ({ open, onClose, onConfirm }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Confirma</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        ¿Esta seguro que quiere eliminar este paciente?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm} autoFocus>
        Eliminar
      </Button>
      <Button onClick={onClose}>Cancelar</Button>
    </DialogActions>
  </Dialog>
);

// eslint-disable-next-line react/prop-types
const SecondConfirmDialog = ({ open, onClose, onConfirm }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Confirma</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Toda la información relacionada a este paciente sera eliminada y no podrá ser recuperada,
        ¿desea continuar?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm} autoFocus>
        Eliminar
      </Button>
      <Button onClick={onClose}>Cancelar</Button>
    </DialogActions>
  </Dialog>
);

UserTableContent.propTypes = {
  filterName: PropTypes.string,
  filteredUsers: PropTypes.array,
  TABLE_HEAD: PropTypes.array,
  handleRequestSort: PropTypes.func,
  setRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  emptyRows: PropTypes.number,
  isUserNotFound: PropTypes.bool
};

export default function UserTableContent({
  filterName,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  TABLE_HEAD,
  filteredUsers,
  page,
  rowsPerPage,
  emptyRows,
  isUserNotFound,
  setRowsPerPage
}) {
  const { data: countData } = useQuery(patientsCount);
  const [deletePatients, { loading: deletePatientsLoading, error: deletePatientsError }] =
    useMutation(deletePatientsMutation, {
      refetchQueries: [
        { query: patientByDetailQuery, variables: { detail: filterName } },
        { query: patientsByEnum, variables: { number: rowsPerPage } }
      ]
    });

  const [deleteFirstConfirmModal, setDeleteFirstConfirmModal] = React.useState(false);
  const [deleteSecondConfirmModal, setDeleteSecondConfirmModal] = React.useState(false);
  const [selectedPatient, setSelectedPatient] = React.useState(null);

  const labelDisplayedRows = ({ from, to, count }) => {
    let countDisplay;
    if (count !== -1) {
      if (typeof countData === 'undefined') {
        countDisplay = 100;
      } else {
        countDisplay = countData.getPatientsCount;
      }
    } else {
      countDisplay = `Mas que ${to}`;
    }

    return `${from}-${to} de ${countDisplay}`;
  };

  const handleDeleteSelected = () => {
    setDeleteFirstConfirmModal(false);
    setDeleteSecondConfirmModal(false);
    deletePatients({
      variables: {
        patientIds: [selectedPatient]
      }
    }).then(({ data }) => {
      console.log('this is the result', data);
    });
    setSelectedPatient(null);
  };

  const handleDelete = (patientId) => {
    setSelectedPatient(patientId);
    setDeleteFirstConfirmModal(true);
  };

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const { id, firstName, lastName, cedula, email, dateOfBirth, sex } = row;
                  const gender = translateSex(sex);
                  const formatedDateOfBirth = dateOfBirth
                    ? dateFormater(new Date(Number(dateOfBirth)).toISOString().split('T')[0])
                    : null;

                  return (
                    <TableRow
                      onClick={() => {
                        setRowsPerPage(5);
                      }}
                      component={RouterLink}
                      to={id}
                      sx={{ textDecoration: 'none', color: 'inherit', alignItems: 'center' }}
                      hover
                      key={id}
                      tabIndex={-1}
                    >
                      <TableCell align="left">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="subtitle2" noWrap>
                            {`${firstName} ${lastName}`}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{cedula}</TableCell>
                      <TableCell align="left">{email}</TableCell>
                      <TableCell align="left">{formatedDateOfBirth}</TableCell>
                      <TableCell align="left">{gender}</TableCell>
                      <TableCell align="left">
                        <ContextPatientMenu handleDelete={() => handleDelete(id)} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        backIconButtonProps={{
          style: { display: 'none' }
        }}
        nextIconButtonProps={{
          style: { display: 'none' }
        }}
        rowsPerPageOptions={[
          5,
          10,
          25,
          {
            label: 'Todos',
            value: typeof countData === 'undefined' ? 100 : countData.getPatientsCount
          }
        ]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        labelDisplayedRows={labelDisplayedRows}
        labelRowsPerPage="Pacientes por pagina"
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <FirstConfirmDialog
        open={deleteFirstConfirmModal}
        onClose={() => setDeleteFirstConfirmModal(false)}
        onConfirm={() => {
          setDeleteFirstConfirmModal(false);
          setDeleteSecondConfirmModal(true);
        }}
        selected={selectedPatient}
      />
      <SecondConfirmDialog
        open={deleteSecondConfirmModal}
        onClose={() => setDeleteSecondConfirmModal(false)}
        onConfirm={handleDeleteSelected}
      />
    </>
  );
}

function translateSex(sex) {
  if (sex === 'male') {
    return 'Hombre';
  }
  if (sex === 'female') {
    return 'Mujer';
  }
  if (sex === '') {
    return '';
  }
  return 'otro';
}
