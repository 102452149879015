import React from 'react';
import { TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';

const HandleFocusAndBlur = (props) => (
  <div
    onFocus={() => {
      if (props.disabled) {
        props.event(true);
      }
    }}
    onBlur={() => {
      if (props.disabled) {
        props.event(false);
      }
    }}
  >
    {props.children}
  </div>
);

export default HandleFocusAndBlur;
