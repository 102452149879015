import { gql } from '@apollo/client';

export const consultationDataFilesQuery = gql`
  query getConsultationDataFiles($consultationId: ID!) {
    getConsultationDataFiles(consultationId: $consultationId) {
      name
      mime_type
      date
      key
    }
  }
`;
