import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Typography } from '@mui/material';
// layouts
import LogoTooth from 'components/LogoTooth';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 3)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Dental by Aronin">
      <AuthLayout>
        ¿No tienes una cuenta? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Regístrate
        </Link>
      </AuthLayout>

      <ContentStyle>
        <Stack sx={{ mb: 5 }}>
          <LogoTooth
            style={{ width: '20%', marginLeft: 'auto', marginRight: 'auto', marginBottom: 50 }}
          />
          <Typography variant="h4" gutterBottom>
            Inicia sesión en Dental By Aronin.
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Ingresa los datos de tu cuenta</Typography>
        </Stack>
        {/* <AuthSocial /> */}

        <LoginForm />

        <MHidden width="smUp">
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            ¿No tienes una cuenta?&nbsp;
            <Link variant="subtitle2" component={RouterLink} to="/register">
              Regístrate
            </Link>
          </Typography>
        </MHidden>
      </ContentStyle>
    </RootStyle>
  );
}
