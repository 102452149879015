// @ts-nocheck
import { useQuery } from '@apollo/client';
import { FormControlLabel, Grid, InputAdornment, Switch, Tooltip, Typography } from '@mui/material';
import { EventSubForm } from 'components/Form/EventForm';
import { Field, Form, Formik } from 'formik';
import { groupUsersQuery } from 'graphql/querys/groupUsers';
import { useEffect, useState } from 'react';
import { formatCognitoUsers } from 'utils/formatCognitoUsers';
import * as yup from 'yup';
import Select from '../../Form/SelectWrapper';
import SubmitButtonWrapper from '../../Form/SubmitButtonWrapper';
import TextField from '../../Form/TextFieldWrapper';
import { ConsultationFiles } from '../../files/ConsultationFiles';
import UploadFiles from '../../files/UploadFiles';
import { PaymentsByConsultation } from '../userdetail/PaymentsByConsultation';

const FORM_VALIDATION = yup
  .object()
  .shape({
    consultationDescription: yup.string().required('La descripcion de la consulta es necesaria'),
    event: yup.object(),
    doctor: yup.string(),
    notes: yup.string(),
    totalAmount: yup
      .number()
      .integer()
      .typeError('Por favor ingrese un monto valido')
      .required('El monto de la consulta es necesario'),
    payedAmount: yup
      .number()
      .typeError('Por favor ingrese un monto valido')
      .max(
        yup.ref('totalAmount'),
        'El monto cancelado debe ser inferior al monto total de la consulta'
      )
      .min(0, 'El monto cancelado debe ser superior o igual a 0')
  })
  .required();

const date = new Date().toISOString().split('T');
console.log(date);

const INITAL_FORM_STATE = {
  consultationDescription: '',
  doctor: 'indefinido',
  notes: '',
  payedAmount: 0,
  totalAmount: '',
  event: {}
};

export function ConsultationForm(props) {
  const [currentValues, setCurrentValues] = useState(props.values);

  useEffect(() => {
    setCurrentValues(props.values);
  }, [props.values]);

  if (props.values) {
    currentValues.doctor = currentValues.event.mandatoryPresence?.split(',')[0] || 'indefinido';
  }
  const [files, setFiles] = useState([]);
  const [isConsultationInfoLocked, setIsConsultationInfoLocked] = useState(!!props.values);
  const handleConsultationInfoChange = (event) => {
    setIsConsultationInfoLocked(!event.target.checked);
  };
  const { data: groupUsers, loading: groupUsersLoading } = useQuery(groupUsersQuery);
  let formattedUsers = {
    indefinido: '(indefinido)'
  };
  // @ts-ignore
  if (groupUsers) formattedUsers = formatCognitoUsers(groupUsers.getGroupUsers, formattedUsers);
  return (
    <Formik
      initialValues={props.values ? currentValues : { ...INITAL_FORM_STATE }}
      enableReinitialize
      validationSchema={FORM_VALIDATION}
      onSubmit={(_values) => {
        props.onSubmit(_values, files);
        setFiles([]);
      }}
    >
      <Form>
        <Grid
          container
          rowSpacing={5}
          columnSpacing={3}
          columns={{ xs: 12 }}
          mb={3}
          p={{ xs: 1, sm: 3 }}
        >
          <Grid item xs={6}>
            <Typography variant="h5" gutterBottom pt={3}>
              Información de la consulta
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right', marginTop: '21px' }}>
            {props.values && (
              <FormControlLabel
                control={
                  <Switch
                    checked={!isConsultationInfoLocked}
                    onChange={handleConsultationInfoChange}
                  />
                }
                label="Editar datos"
                labelPlacement="start"
              />
            )}
          </Grid>
        </Grid>
        <Tooltip
          title={isConsultationInfoLocked ? 'Haga clic en "Editar datos" para editar' : ''}
          followCursor
        >
          <Grid
            container
            rowSpacing={5}
            columnSpacing={3}
            columns={{ xs: 6, sm: 6, md: 12 }}
            p={{ xs: 2, sm: 3 }}
          >
            <Grid item xs={6}>
              <TextField
                name="consultationDescription"
                label="Motivo de la consulta"
                inputProps={{
                  readOnly: isConsultationInfoLocked
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="event"
                component={EventSubForm({
                  isStateDisabled: props.values ? props.values.payedAmount !== 0 : false,
                  isDisabled: isConsultationInfoLocked
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="notes"
                label="Notas"
                multiline
                rows={4}
                variant="outlined"
                inputProps={{
                  readOnly: isConsultationInfoLocked
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {groupUsersLoading && <p>Loading doctors...</p>}
              {!groupUsersLoading && (
                <Select
                  name="doctor"
                  label="Doctor"
                  options={formattedUsers}
                  inputProps={{
                    readOnly: isConsultationInfoLocked
                  }}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="totalAmount"
                label="Monto de la consulta"
                variant="outlined"
                InputProps={{
                  readOnly: isConsultationInfoLocked,
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {props.values && (
                <TextField
                  name="payedAmount"
                  label="Monto cancelado"
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
              )}
            </Grid>
            {props.values ? (
              <Grid item xs={12} style={{ width: '100%' }}>
                <PaymentsByConsultation />
              </Grid>
            ) : null}
            {props.values ? (
              <Grid item xs={12}>
                <ConsultationFiles />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              {!isConsultationInfoLocked && <UploadFiles files={files} setFiles={setFiles} />}
            </Grid>
            <Grid item xs={12}>
              <SubmitButtonWrapper>Guardar Consulta</SubmitButtonWrapper>
            </Grid>
          </Grid>
        </Tooltip>
      </Form>
    </Formik>
  );
}
