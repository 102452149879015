import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';

// eslint-disable-next-line react/prop-types
export function Calendar({ events }) {
  return (
    <FullCalendar
      locale="es"
      buttonText={{
        today: 'Hoy',
        month: 'Mes',
        week: 'Semana',
        list: 'Lista'
      }}
      plugins={[timeGridPlugin, dayGridPlugin, listPlugin]}
      initialView="timeGridWeek"
      height={580}
      allDaySlot={false}
      headerToolbar={{
        start: 'prev,today',
        center: 'title',
        end: 'next'
      }}
      footerToolbar={{
        left: 'timeGridWeek,dayGridMonth,listMonth'
      }}
      businessHours={{
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday

        startTime: '6:00', // a start time (10am in this example)
        endTime: '19:00' // an end time (6pm in this example)
      }}
      nowIndicator
      events={events}
    />
  );
}
