import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import GroupList from 'pages/manager/GroupList';
import UserList from 'pages/manager/UserList';
import CreateGroup from 'pages/manager/CreateGroup';
import Scheduler from 'pages/Scheduler';
import Manager from './pages/manager/Manager';
import AddUserToGroup from './pages/manager/AddUserToGroup';
import GroupDetail from './pages/manager/GroupDetail';
import { useAuth } from './AuthContext';
import { Payments } from './components/payments/Payments';
import { ConsultationDetail } from './components/_dashboard/consultations/ConsultationDetail';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Bills from './pages/Bills';
import Blog from './pages/Blog';
import CreateConsultation from './pages/CreateConsultation';
import CreatePatient from './pages/CreatePatient';
import CreatePayment from './pages/CreatePayment';
import DashboardApp from './pages/DashboardApp';
import ForgotPassword from './pages/ForgotPassword';
//
import Login from './pages/Login';
import Logout from './pages/Logout';
import NotFound from './pages/Page404';
import Products from './pages/Products';
import Register from './pages/Register';
import User from './pages/User';
import UserDetail from './pages/UserDetail';

// ----------------------------------------------------------------------

export default function Router() {
  const AuthValue = useAuth();

  return useRoutes([
    {
      path: '/dashboard',
      element: AuthValue() ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/user" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'user/:id', element: <UserDetail /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'logout', element: <Logout /> },
        { path: 'scheduler', element: <Scheduler /> },
        { path: 'createPatient', element: <CreatePatient /> },
        { path: 'createConsultation/:id', element: <CreateConsultation /> },
        { path: 'createPayment/:id', element: <CreatePayment /> },
        { path: 'bills', element: <Bills /> },
        { path: 'bills/:id', element: <UserDetail default="pago" /> },
        { path: 'payments/:id', element: <Payments /> },
        { path: 'consultation/:id/:consultationId', element: <ConsultationDetail /> },
        // Manager
        { path: 'manager', element: <Manager /> },
        { path: 'manager/groups', element: <GroupList /> },
        { path: 'manager/groups/createGroup', element: <CreateGroup /> },
        { path: 'manager/groups/:GroupName', element: <GroupDetail /> },
        { path: 'manager/groups/:GroupName/addUser', element: <AddUserToGroup /> },
        { path: 'manager/users', element: <UserList /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'forgotPassword', element: <ForgotPassword /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
