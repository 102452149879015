import { gql } from '@apollo/client';

export const ConsultationsOwedByPatients = gql`
  query ConsultationsOwedByPatients {
    consultationsOwedByPatients {
      id
      first_name
      last_name
      email
      phone_number
      total_amount_to_pay
    }
  }
`;
