import { useMutation } from '@apollo/client';
import { Icon } from '@iconify/react';

import arrowBack from '@iconify/icons-eva/arrow-back-fill';
import { Alert, Box, Card, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDateWithoutHour } from 'utils/formatDateWithoutHour';

import { PaymentForm } from '../components/payments/PaymentForm';
import { insertPaymentMutation } from '../graphql/mutations/insertPayment';
import { ConsultationsByPatientId } from '../graphql/querys/consultationsByPatientIdQuery';
import { ConsultationsOwedByPatientId } from '../graphql/querys/consultationsOwedByPatientIdQuery';
import { paymentByPatientId } from '../graphql/querys/paymentByPatientIdQuery';
import { paymentByConsultationId } from '../graphql/querys/paymentByConsultationIdQuery';
import { consultationById } from '../graphql/querys/consultationByIdQuery';
import { ConsultationsOwedByPatients } from '../graphql/querys/consultationsOwedByPatients';

import { toSnakeCase } from '../utils/toSnakeCase';

export default function CreatePayment(props) {
  const navigate = useNavigate();
  const [totalDebtSurpassed, setTotalDebtSurpassed] = useState(0);
  const [invalidPayment, setInvalidPayment] = useState('');
  const [invalidPaymentByConsultation, setInvalidPaymentByConsultation] = useState('');
  const [currentConsultationId, setCurrentConsulationId] = useState(null);

  const { id } = useParams();

  const [insertPayment, { data: PaymentData, loading: PaymentLoading, error: PaymentError }] =
    useMutation(insertPaymentMutation, {
      // Then re-run
      refetchQueries: [
        { query: ConsultationsByPatientId, variables: { consultationsByPatientIdId: id } },
        { query: paymentByPatientId, variables: { paymentByPatientIdId: id } },
        { query: ConsultationsOwedByPatientId, variables: { id } },
        { query: ConsultationsOwedByPatients },
        {
          query: paymentByConsultationId,
          variables: { paymentByConsultationIdId: currentConsultationId }
        },

        { query: consultationById, variables: { consultationByIdId: currentConsultationId } }
      ],
      awaitRefetchQueries: true
    });

  const onSubmit = async (values, consultationId) => {
    if (consultationId === 'distribuir') {
      consultationId = null;
    } else {
      setCurrentConsulationId(consultationId);
    }
    const paymentData = toSnakeCase(values);
    paymentData.consultation_id = parseInt(consultationId, 10);
    paymentData.patient_id = +id;
    paymentData.payed_amount = parseFloat(paymentData.payed_amount);
    paymentData.payment_date = formatDateWithoutHour(paymentData.payment_date);
    // TODO : unparse consultationDate to event.begin
    // paymentData.event.begin =
    const result = await insertPayment({
      variables: { paymentData }
    });

    if (!PaymentError) {
      navigate(`/dashboard/user/${id}`, { replace: true });
    }
    const paymentFieldError = result.data.insertPayment.paymentError;
    console.log(paymentFieldError);
    if (paymentFieldError && paymentFieldError.field === 'Payment surpassed') {
      setTotalDebtSurpassed(paymentFieldError.totalDebt);
    } else if (paymentFieldError && paymentFieldError.field === 'Invalid payment') {
      setInvalidPayment(paymentFieldError.field);
    } else if (
      paymentFieldError &&
      paymentFieldError.field === 'Payment by consultation surpassed'
    ) {
      setInvalidPaymentByConsultation(paymentFieldError.totalDebt);
    } else {
      props.toClose();
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mx={5} mt={5}>
        <Tooltip title="Regresar">
          <IconButton onClick={props.toClose}>
            <Icon icon={arrowBack} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Box p="30px">
        <Card sx={{ boxShadow: 3 }}>
          <Box px={{ xs: 2, sm: 5 }} py={3}>
            <Typography variant="h4" gutterBottom>
              Ingresar pago
            </Typography>
          </Box>
          <Box px={{ xs: 1, sm: 5 }} py={3}>
            <PaymentForm onSubmit={onSubmit} />
            {totalDebtSurpassed ? (
              <Box py={3}>
                <Alert severity="error">{`El pago realizado excede la deuda total del paciente ($ ${totalDebtSurpassed})`}</Alert>
              </Box>
            ) : null}
            {invalidPayment ? (
              <Box py={3}>
                <Alert severity="error">
                  El pago no puede ser ingresado, ya que no hay ninguna consulta por pagar
                </Alert>
              </Box>
            ) : null}
            {invalidPaymentByConsultation ? (
              <Box py={3}>
                <Alert severity="error">{`El pago realizado excede la deuda total de la consulta ($ ${invalidPaymentByConsultation})`}</Alert>
              </Box>
            ) : null}
          </Box>
        </Card>
      </Box>
    </>
  );
}
