export function toFullCalendarEvent(aroninEvent) {
  // Type Consultation
  if (aroninEvent.consultation_id) {
    if (!aroninEvent.patient) throw Error('Aronin event has consultation_id but no patient');
    return {
      id: aroninEvent.id,
      start: new Date(aroninEvent.begin),
      end: new Date(aroninEvent.end),
      title: `${aroninEvent.patient.first_name} ${aroninEvent.patient.last_name}`,
      backgroundColor: '#0077b6', // Aronin blue
      url: `consultation/${aroninEvent.patient.id}/${aroninEvent.consultation_id}`,
      editable: true
    };
  }
  return null;
}
