import { gql } from '@apollo/client';

export const patientByDetailQuery = gql`
  query PatientByDetail($detail: String!) {
    patientByDetail(detail: $detail) {
      id
      first_name
      last_name
      date_of_birth
      place_of_birth
      sex
      cedula
      email
      phone_number
      emergency_phone_number
      ocupation
      address
    }
  }
`;
