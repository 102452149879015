import { Box, Button, Card, Dialog, Grid, IconButton, InputAdornment } from '@mui/material';
import { Icon } from '@iconify/react';
import DeleteIcon from '@iconify/icons-eva/close-square-outline';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import CreatePayment from 'pages/CreatePayment';
import * as yup from 'yup';
import { useQuery } from '@apollo/client';
import { OwedConsultations } from 'components/_dashboard/consultations/OwedConsultations';
import { PayableConsultations } from 'components/_dashboard/consultations/PayableConsultations';
import { ConsultationsByPatientId } from 'graphql/querys/consultationsByPatientIdQuery';
import { useParams } from 'react-router-dom';
import { fromUTCdatetimeToLocalDate } from 'utils/formatTime';
import { toCamelCase } from 'utils/toCamelCase';
import { ConsultationsOwedByPatientId } from 'graphql/querys/consultationsOwedByPatientIdQuery';
import SubmitButtonWrapper from '../Form/SubmitButtonWrapper';
import DateTimePicker from '../Form/DateTimeWrapper';
import TextField from '../Form/TextFieldWrapper';

const FORM_VALIDATION = yup
  .object()
  .shape({
    reference: yup.string(),
    paymentDate: yup.string().required('consultation date is required'),
    paymentMethod: yup.string(),
    payedAmount: yup
      .number()
      .typeError('Please enter a valid number')
      .min(1, 'El monto cancelado debe ser superior a 0')
  })
  .required();

const date = new Date().toISOString().split('T');
console.log(date);

const INITAL_FORM_STATE = {
  reference: '',
  paymentDate: date[0],
  paymentMethod: '',
  payedAmount: 0
};

function formattedDate(d = new Date()) {
  return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('/');
}

export function PaymentForm(props) {
  const { id } = useParams();
  const [consultationId, setConsultationId] = useState(null);
  const [consultationDescription, setConsultationDescription] = useState('');
  const [consultationDate, setConsultationDate] = useState('');
  const {
    data: pData,
    loading: consultationsLoading,
    error: consultationsError
  } = useQuery(ConsultationsOwedByPatientId, {
    variables: { id }
  });
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const consultations = toCamelCase(pData.consultationsOwedByPatientId);
  consultations.map(
    (consultation) =>
      (consultation.consultationDate = formattedDate(new Date(consultation.event.begin)))
  );
  const owedConsultations = consultations.filter((consultation) => consultation.amountToPay !== 0);
  return (
    <Formik
      initialValues={INITAL_FORM_STATE}
      validationSchema={FORM_VALIDATION}
      onSubmit={(values) => props.onSubmit(values, consultationId)}
    >
      <Form>
        <Grid
          container
          rowSpacing={5}
          columnSpacing={3}
          alignItems="center"
          columns={{ xs: 6, sm: 6, md: 12 }}
        >
          <Grid item xs={6}>
            <TextField name="reference" label="Referencia de pago" />
          </Grid>
          <Grid item xs={6}>
            <DateTimePicker name="paymentDate" label="Fecha de pago" />
          </Grid>
          <Grid item xs={6}>
            <TextField name="paymentMethod" label="Metodo de pago" />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="payedAmount"
              label="Monto del pago"
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ boxShadow: 3 }}>
              <Box px={3}>
                <PayableConsultations
                  setConsultationId={setConsultationId}
                  setConsultationDate={setConsultationDate}
                  setConsultationDescription={setConsultationDescription}
                  owedConsultations={owedConsultations}
                  selectedId={[consultationId]}
                  toClose={handleClose}
                />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={6}>
            {/* <Button
              variant="outlined"
              disabled={consultationId === 'distribuir'}
              onClick={() => setConsultationId('distribuir')}
            >
              {consultationId === 'distribuir' && '(seleccionado)'} Distribuir automaticamente el
              pago entre consultas
            </Button> */}
          </Grid>

          <Grid item xs={12}>
            <SubmitButtonWrapper disabled={!consultationId}>Guardar Pago</SubmitButtonWrapper>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}
