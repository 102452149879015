export const putS3 = async (files, presignedUploadUrls) => {
  const response = [];
  for (let i = 0; i < files.length; i += 1) {
    response.push(
      fetch(presignedUploadUrls[i], {
        method: 'PUT',
        body: files[i]
      })
    );
  }
  await Promise.all(response);
  return response;
};

export const dataURLtoFile = async (dataUrl, fileName) => {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
};

export const getS3 = async (presignedDownloadUrls) => {
  const response = await fetch(presignedDownloadUrls);
  const url = URL.createObjectURL(await response.blob());
  console.log(url);
  window.open(url);
};
