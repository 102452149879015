// @ts-nocheck
import { useQuery } from '@apollo/client'; // material
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Button, Card, Container, LinearProgress, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// components
import ListToolbar from 'components/_dashboard/user/ListToolbar';
import Page from '../components/Page';
import UserTableContent from '../components/_dashboard/user/UserTableContent';
import { patientByDetailQuery } from '../graphql/querys/patientByDetailQuery';
import { patientsByEnum } from '../graphql/querys/patientsByEnum';
import { toCamelCase } from '../utils/toCamelCase';
import { revertFormattedDate, getComparator } from '../utils/comparator';

const TABLE_HEAD = [
  // THE id here is used to control the sort value, it has to correspond to the value to be compared
  { id: 'firstName', label: 'Nombre', alignRight: false },
  { id: 'cedula', label: 'Cédula', alignRight: false },
  { id: 'email', label: 'Correo', alignRight: false },
  { id: 'dateOfBirth', label: 'Fecha de nacimiento', alignRight: false },
  { id: 'sex', label: 'Género', alignRight: false }
  // { id: '' }
];

// TODO: refactor to utils/comparator.js
function descendingComparator(a, b, orderBy) {
  const checkDateA = revertFormattedDate(a[orderBy]);
  const checkDateB = revertFormattedDate(b[orderBy]);
  if (checkDateA !== -1 && checkDateB !== -1) {
    // This means that a and b are dates
    if (checkDateB.getTime() < checkDateA.getTime()) {
      console.log('-1');
      return -1;
    }
    if (checkDateB.getTime() > checkDateA.getTime()) {
      console.log('1');
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    console.log('-1');
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    console.log('1');
    return 1;
  }
  return 0;
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1]; // this allows us to keep original order in case of similar order values 'A LITTLE OVERKILL'
  });

  // Pass query as parameter for front side search
  // if (query) {
  //   return filter(
  //     array,
  //     (_user) =>
  //       `${_user.firstName.toLowerCase()} ${_user.lastName.toLowerCase()}`.indexOf(
  //         query.toLowerCase()
  //       ) !== -1
  //   );
  // }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('lastName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  if (rowsPerPage === -1) {
    setRowsPerPage(100);
  }

  const {
    data: pData,
    loading: patientLoading,
    error: patientError
  } = useQuery(patientByDetailQuery, {
    variables: { detail: filterName }
  });

  const { data: idleData, loading: idleLoading } = useQuery(patientsByEnum, {
    variables: { number: rowsPerPage }
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  if (patientLoading || idleLoading)
    return (
      <Page title="Dental by Aronin">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Pacientes
            </Typography>
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/createPatient"
              startIcon={<Icon icon={plusFill} />}
              onClick={() => setRowsPerPage(5)}
            >
              Nuevo paciente
            </Button>
          </Stack>
          <Card sx={{ boxShadow: 3 }}>
            <ListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              placeholder="Buscar paciente..."
            />
            <br />
            <LinearProgress />
          </Card>
        </Container>
      </Page>
    );
  if (!pData || idleLoading)
    return (
      <Page title="Dental by Aronin">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Pacientes
            </Typography>
          </Stack>
          <Card sx={{ boxShadow: 3, padding: 5 }}>
            Lo sentimos, su sesión ha expirado. Por motivos de seguridad, por favor cierre sesión e
            inicie sesión nuevamente
            <br />
            <br />
            <Button
              component={RouterLink}
              to="/dashboard/logout"
              color="inherit"
              variant="contained"
            >
              Cerrar sesión
            </Button>
          </Card>
        </Container>
      </Page>
    );
  if (patientError) return <p>ERROR</p>;

  let patientData = [];

  if (pData.patientByDetail.length !== 0 || filterName !== '') {
    patientData = toCamelCase(pData.patientByDetail);
  } else {
    patientData = toCamelCase(idleData.getPatientsEnumerated);
  }

  // This empty rows value sets in when in a page > 0, there are not enough entries to fill the value per page
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - patientData.length) : 0;

  console.log('this is patient data', patientData);

  const filteredUsers = applySortFilter(patientData, getComparator(order, orderBy));

  console.log('look at the filtered users', filteredUsers);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Dental by Aronin">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Pacientes
          </Typography>
          <Button
            onClick={() => window.dataLayer.push({ event: 'create_patient' })}
            variant="contained"
            component={RouterLink}
            to="/dashboard/createPatient"
            startIcon={<Icon icon={plusFill} />}
          >
            Nuevo paciente
          </Button>
        </Stack>

        <Card sx={{ boxShadow: 3 }}>
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder="Buscar paciente..."
          />

          <UserTableContent
            selected={selected}
            filterName={filterName}
            handleRequestSort={handleRequestSort}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            order={order}
            orderBy={orderBy}
            TABLE_HEAD={TABLE_HEAD}
            filteredUsers={filteredUsers}
            page={page}
            rowsPerPage={rowsPerPage}
            emptyRows={emptyRows}
            isUserNotFound={isUserNotFound}
          />
        </Card>
      </Container>
    </Page>
  );
}
