import { gql } from '@apollo/client';

export const signatureDataFilesQuery = gql`
  query getSignatureDataFiles($patientId: ID!) {
    getSignatureDataFiles(patientId: $patientId) {
      date
      key
    }
  }
`;
