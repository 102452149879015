import { useMutation, useQuery } from '@apollo/client'; // material
import { Card, Container, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { addUserToGroupMutation } from 'graphql/mutations/addUserToGroup';
// components
import { specificGroupUsersQuery } from 'graphql/querys/specificGroupUsers';
import Page from 'components/Page';
import UserListTable from 'components/manager/UserListTable';

// ----------------------------------------------------------------------

export default function AddUserToGroup() {
  const { GroupName } = useParams();

  const { data: usersQuery, loading: usersQueryLoading } = useQuery(specificGroupUsersQuery, {
    variables: { GroupName }
  });

  const [
    addUserToGroup,
    { data: addUserFromGroupData, loading: addUserFromGroupLoading, error: addUserFromGroupError }
  ] = useMutation(addUserToGroupMutation, {
    // Then re-run
    refetchQueries: [{ query: specificGroupUsersQuery, variables: { GroupName } }]
  });

  if (usersQueryLoading) {
    return (
      <Page title="Dental by Aronin">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Add user to {GroupName}
            </Typography>
          </Stack>
          <Card sx={{ boxShadow: 3 }}>
            <p>Loading data...</p>
          </Card>
        </Container>
      </Page>
    );
  }

  const handlAaddUserToGroup = async (sub) => {
    await addUserToGroup({
      variables: {
        sub,
        GroupName
      }
    });
  };

  return (
    <Page title="Dental by Aronin">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Add user to {GroupName}
          </Typography>
        </Stack>
        <Card sx={{ boxShadow: 3 }}>
          <UserListTable actionTitle="Add to group" actionHandler={handlAaddUserToGroup} />
        </Card>
      </Container>
    </Page>
  );
}
