import { gql } from '@apollo/client';

export const patientsByEnum = gql`
  query GetPatientsEnumerated($number: Int!) {
    getPatientsEnumerated(number: $number) {
      id
      first_name
      last_name
      date_of_birth
      sex
      cedula
      place_of_birth
      email
      phone_number
      emergency_phone_number
      ocupation
      address
    }
  }
`;
