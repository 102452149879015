import { gql } from '@apollo/client';

export const deleteMultiplePaymentsByIdMutation = gql`
  mutation deleteMultiplePaymentsByIdMutation($paymentsData: DeleteMultiplePaymentInput!) {
    deleteMultiplePaymentsById(paymentsData: $paymentsData) {
      paymentData
      paymentError {
        field
        message
        totalDebt
      }
    }
  }
`;
