import { gql } from '@apollo/client';

export const insertPaymentMutation = gql`
  mutation insertPayment($paymentData: PaymentInput!) {
    insertPayment(paymentData: $paymentData) {
      paymentData {
        payment_id
        patient_id
        reference
        consultation_id
        payment_date
        payment_method
        payed_amount
      }
      paymentError {
        field
        message
        totalDebt
      }
    }
  }
`;
