import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';

import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useState } from 'react';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import {
  Link,
  Alert,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  Box,
  InputAdornment,
  FormControlLabel,
  Container,
  Typography,
  Card
} from '@mui/material';

export default function ForgotPasswordForm(props) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);

  const LoginSchema = Yup.object().shape({
    code: Yup.string().required('Se requiere un código de verificación'),
    password: Yup.string().required('Por favor, ingrese una contraseña')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      code: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (data) => {
      try {
        Auth.forgotPasswordSubmit(props.userEmail, data.code, data.password)
          .then((data) => {
            console.log(data);
            setPasswordChangeSuccess(true);
            setTimeout(() => navigate('/login', { replace: true }), 1000);
          })
          .catch((err) => console.log(err));

        console.log(data);
      } catch (error) {
        console.log('Error!!danger', error);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            type="code"
            label="Verification Code"
            {...getFieldProps('code')}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="New password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          {passwordChangeSuccess ? (
            <Box py={3}>
              <Alert severity="success">Su contraseña ha sido cambiadad</Alert>
            </Box>
          ) : null}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Change password
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
