import { gql } from '@apollo/client';

export const paymentByPatientId = gql`
  query paymentByPatientId($paymentByPatientIdId: ID!) {
    paymentByPatientId(id: $paymentByPatientIdId) {
      payment_id
      patient_id
      reference
      consultation_id
      payment_date
      payment_method
      payed_amount
      event {
        begin
      }
      consultation_description
    }
  }
`;
