import { gql } from '@apollo/client';

export const updatePatientAndMedicalHistoryMutation = gql`
  mutation updatePatientAndMedicalHistory(
    $id: ID!
    $patientData: PatientInput!
    $medicalHistoryData: PatientMedicalHistoryInput!
  ) {
    updatePatientAndMedicalHistory(
      id: $id
      patientData: $patientData
      medicalHistoryData: $medicalHistoryData
    ) {
      patientData {
        id
        first_name
        last_name
        date_of_birth
        sex
        place_of_birth
        cedula
        email
        phone_number
        emergency_phone_number
        ocupation
        address
      }
      medicalHistoryData {
        id
        id_patient
        medical_antecedents
        odontodiagram
        notes
        attached_files
        family_antecedents
        oral_history
        allergies
        habits
      }
      patientAndMedicalHistoryError {
        field
        message
      }
    }
  }
`;
