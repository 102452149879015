import { gql } from '@apollo/client';

export const specificGroupUsersQuery = gql`
  query getSpecificGroupUsers($GroupName: String!) {
    getSpecificGroupUsers(GroupName: $GroupName) {
      Attributes {
        Name
        Value
      }
    }
  }
`;
