/** Transform recieved object to a form-ready object
  Example of formattedUsers value :
  ```
  {
      'e531c481-51c2-4620-ba81-976d38bb68e2': 'Vides Leonardo',
      '84ed963c-b729-4fcc-852f-324499b6be5a': 'Rolland Léo'
  }
  ```
  formattedUsers: initial object to where to add formatted users
  */
export function formatCognitoUsers(groupUsers, formattedUsers = {}) {
  groupUsers.forEach((gu) => {
    formattedUsers = {
      ...formattedUsers,
      [gu.Attributes.find((a) => a.Name === 'sub').Value]: `${
        gu.Attributes.find((a) => a.Name === 'name').Value
      } ${gu.Attributes.find((a) => a.Name === 'family_name').Value}`
    };
  });
  return formattedUsers;
}
