import { gql } from '@apollo/client';

export const PatientDetailById = gql`
  query patientDetailByIdQuery($id: ID!) {
    patientById(id: $id) {
      id
      first_name
      last_name
      date_of_birth
      sex
      place_of_birth
      phone_number
      ocupation
      emergency_phone_number
      email
      cedula
      address
    }
    consultationsByPatientId(id: $id) {
      consultation_id
      doctor
      patient_id
      event {
        begin
        state
      }
      notes
      payed_amount
      amount_to_pay
      total_amount
    }
    medicalHistoryByPatientId(id: $id) {
      medical_antecedents
      odontodiagram
      notes
      attached_files
      family_antecedents
      oral_history
      allergies
      habits
    }
  }
`;
