// @ts-nocheck
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import arrowBack from '@iconify/icons-eva/arrow-back-fill';
import { Icon } from '@iconify/react';
import { Alert, IconButton, Tooltip, Box, Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConsultationsOwedByPatientId } from 'graphql/querys/consultationsOwedByPatientIdQuery';
import { updateConsultationMutation } from '../../../graphql/mutations/updateConsultation';
import { consultationById } from '../../../graphql/querys/consultationByIdQuery';
import { consultationDataFilesQuery } from '../../../graphql/querys/consultationDataFilesQuery';
import { ConsultationsByPatientId } from '../../../graphql/querys/consultationsByPatientIdQuery';
import { generateConsultationDataUploadURL } from '../../../graphql/querys/generateConsultationDataUploadUrlQuery';
import { putS3 } from '../../../utils/s3Actions';
import { toCamelCase } from '../../../utils/toCamelCase';
import { toSnakeCase } from '../../../utils/toSnakeCase';
import { ConsultationForm } from './ConsultationForm';

export function ConsultationDetail() {
  const { id, consultationId } = useParams();
  const [confimEdit, setConfirmEdit] = useState(false);
  const [
    updateConsultation,
    {
      data: consultationUpdateData,
      loading: consultationUpdateLoading,
      error: consultationUpdateError
    }
  ] = useMutation(updateConsultationMutation, {
    // Then re-run
    refetchQueries: [
      {
        query: ConsultationsByPatientId,
        variables: { consultationsByPatientIdId: id }
      },
      { query: consultationById, variables: { consultationByIdId: consultationId } },
      { query: ConsultationsOwedByPatientId, variables: { id } }
    ]
  });

  const { data: consultationData, loading: consultationLoading } = useQuery(consultationById, {
    variables: { consultationByIdId: consultationId }
  });

  const [generateConsultationDataUploadURLQuery, { error, data: fileData, loading: fileLoading }] =
    useLazyQuery(generateConsultationDataUploadURL);

  const { refetch: refetchData, data: datsss } = useQuery(consultationDataFilesQuery, {
    variables: { consultationId }
  });

  if (consultationLoading || !consultationData) {
    return <Skeleton />;
  }
  const formValues = toCamelCase(consultationData.consultationById);

  const onSubmit = async (values, files) => {
    const consultationData = toSnakeCase(values);
    consultationData.payed_amount = parseFloat(consultationData.payed_amount);
    consultationData.amount_to_pay =
      parseFloat(consultationData.total_amount) - parseFloat(consultationData.payed_amount);
    consultationData.total_amount = parseFloat(consultationData.total_amount);
    consultationData.event.patient_id = consultationData.patient_id;
    consultationData.event.mandatory_presence = consultationData.doctor;

    delete consultationData.event.id;
    delete consultationData.event.consultation_id;
    delete consultationData.event.created_at;
    delete consultationData.event.created_by;
    delete consultationData.event.updated_at;
    delete consultationData.event.last_update_by;
    delete consultationData.event.typename;

    delete consultationData.consultation_id;
    delete consultationData.patient_id;
    delete consultationData.typename;

    await updateConsultation({
      variables: { consultationData, updateConsultationId: consultationId }
    });

    const urlData = files;
    const fileData = files.map((a) => a.file);
    urlData.map((file) => delete file.file);

    const urlInput = toSnakeCase(urlData);
    const urls = await generateConsultationDataUploadURLQuery({
      variables: {
        consultationId,
        arrayMetadata: urlInput
      }
    });
    await putS3(fileData, urls.data.generateConsultationDataUploadURL);

    refetchData();

    if (!consultationUpdateError) {
      setConfirmEdit(true);
      setTimeout(() => {
        setConfirmEdit(false);
      }, 4000);
    }
  };
  return (
    <>
      <ConsultationForm values={formValues} onSubmit={onSubmit} />
      {confimEdit && <Alert severity="success">La consulta ha sido editada</Alert>}
    </>
  );
}
