// scroll bar
import 'simplebar/src/simplebar.css';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { Amplify, Auth } from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloClient, ApolloProvider, InMemoryCache, gql } from '@apollo/client';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import TagManager from 'react-gtm-module';
//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

require('dotenv').config();

Sentry.init({
  dsn: 'https://fc197de40b484e4ba757b2e5ff951209@o1304244.ingest.sentry.io/6544381',
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

Sentry.setContext('App version', {
  version: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENV
});

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
  },
  Storage: {
    // region: config.s3.REGION,
    // bucket: config.s3.BUCKET,
    // identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        // name: "notes",
        // endpoint: config.apiGateway.URL,
        // region: config.apiGateway.REGION
      }
    ]
  }
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
};

TagManager.initialize(tagManagerArgs);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API
});

const authLink = setContext(async (_, { headers }) => {
  const token = await (await Auth.currentSession()).getIdToken().getJwtToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log('graphQLErrors', graphQLErrors);
  }
  if (networkError) {
    console.log('networkError', networkError);
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache()
});

// const client = new ApolloClient({
//   uri: process.env.REACT_APP_API,
//   cache: new InMemoryCache()
// });

// ----------------------------------------------------------------------

ReactDOM.render(
  <ApolloProvider client={client}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
