import { useQuery } from '@apollo/client'; // material
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import {
  Button,
  Card,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TABLE_HEAD,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { allGroupsQuery } from 'graphql/querys/allGroups';
import { Link as RouterLink } from 'react-router-dom';
// components
import externalLinkOutline from '@iconify/icons-eva/external-link-outline';
import Page from 'components/Page';

export default function Manager() {
  const {
    data: allGroups,
    loading: allGroupsLoading,
    error: allGroupsError
  } = useQuery(allGroupsQuery);

  if (!allGroups)
    return (
      <Page title="Dental by Aronin">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Groups
            </Typography>
          </Stack>
          <Card sx={{ boxShadow: 3 }}>
            <p>Loading data...</p>
          </Card>
        </Container>
      </Page>
    );
  if (allGroupsError) return <p>ERROR</p>;

  const groups = JSON.parse(allGroups.getAllGroups);
  return (
    <Page title="Dental by Aronin">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Manager
          </Typography>
        </Stack>

        <Card sx={{ boxShadow: 3 }}>
          <Button component={RouterLink} to="groups">
            Manage Cognito groups
          </Button>
          <br />
          <Button component={RouterLink} to="users">
            Manage Cognito users
          </Button>
        </Card>
      </Container>
    </Page>
  );
}
