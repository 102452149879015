import { useQuery } from '@apollo/client'; // material
import { Card, Container, Stack, Typography } from '@mui/material';
import { filter } from 'lodash';
import { useState } from 'react';
// components
import ListToolbar from 'components/_dashboard/user/ListToolbar';
import Page from '../components/Page';
import BillsTableContent from '../components/_dashboard/consultations/BillsTableContent';
import { ConsultationsOwedByPatients } from '../graphql/querys/consultationsOwedByPatients';
import { toCamelCase } from '../utils/toCamelCase';
import { revertFormattedDate, getComparator } from '../utils/comparator';

const TABLE_HEAD = [
  // THE id here is used to control the sort value, it has to correspond to the value to be compared
  { id: 'firstName', label: 'Nombre', alignRight: false },
  { id: 'email', label: 'Correo electrónico', alignRight: false },
  { id: 'phoneNumber', label: 'Número de teléfono', alignRight: false },
  { id: 'totalAmountToPay', label: 'Monto total por cancelar', alignRight: false }
];

// TODO: refactor to utils/comparator.js
function descendingComparator(a, b, orderBy) {
  const checkDateA = revertFormattedDate(a[orderBy]);
  const checkDateB = revertFormattedDate(b[orderBy]);
  if (checkDateA !== -1 && checkDateB !== -1) {
    // This means that a and b are dates
    if (checkDateB.getTime() < checkDateA.getTime()) {
      console.log('-1');
      return -1;
    }
    if (checkDateB.getTime() > checkDateA.getTime()) {
      console.log('1');
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    console.log('-1');
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    console.log('1');
    return 1;
  }
  return 0;
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1]; // this allows us to keep original order in case of similar order values 'A LITTLE OVERKILL'
  });

  //   Pass query as parameter for front side search
  if (query) {
    return filter(
      array,
      (_user) =>
        `${_user.firstName.toLowerCase()} ${_user.lastName.toLowerCase()}`.indexOf(
          query.toLowerCase()
        ) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Bills() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('lastName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    data: consultationsOwed,
    loading: consultationsLoading,
    error: consultationsError
  } = useQuery(ConsultationsOwedByPatients);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = [].map((n) => n.firstName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    console.log(newSelected);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  if (!consultationsOwed)
    return (
      <Page title="Dental by Aronin">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Cuentas por cobrar
            </Typography>
          </Stack>

          <Card sx={{ boxShadow: 3 }}>
            <ListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              placeholder="Buscar paciente..."
            />
            <BillsTableContent
              selected={selected}
              filterName={filterName}
              handleFilterByName={handleFilterByName}
              handleRequestSort={handleRequestSort}
              handleSelectAllClick={handleSelectAllClick}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              order={order}
              orderBy={orderBy}
              TABLE_HEAD={TABLE_HEAD}
              filteredUsers={[]}
              page={page}
              rowsPerPage={rowsPerPage}
              emptyRows={0}
              isConsultationNotFound={false}
              handleClick={handleClick}
            />
          </Card>
        </Container>
      </Page>
    );
  if (consultationsError) return <p>ERROR</p>;

  //   const patientData = toCamelCase(consultationsOwed.consu);
  const consultations = toCamelCase(consultationsOwed.consultationsOwedByPatients);
  console.log(consultations);
  //   This empty rows value sets in when in a page > 0, there are not enough entries to fill the value per page
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - consultations.length) : 0;

  const filteredUsers = applySortFilter(consultations, getComparator(order, orderBy), filterName);

  const isConsultationNotFound = filteredUsers.length === 0;

  return (
    <Page title="Dental by Aronin">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Cuentas por cobrar
          </Typography>
        </Stack>

        <Card sx={{ boxShadow: 3 }}>
          <ListToolbar
            singular="pago seleccionado"
            plural="pagos seleccionados"
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder="Buscar paciente..."
          />
          <BillsTableContent
            selected={selected}
            filterName={filterName}
            handleFilterByName={handleFilterByName}
            handleRequestSort={handleRequestSort}
            handleSelectAllClick={handleSelectAllClick}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            order={order}
            orderBy={orderBy}
            TABLE_HEAD={TABLE_HEAD}
            filteredUsers={filteredUsers}
            page={page}
            rowsPerPage={rowsPerPage}
            emptyRows={emptyRows}
            isConsultationNotFound={isConsultationNotFound}
            handleClick={handleClick}
          />
        </Card>
      </Container>
    </Page>
  );
}
