// source: https://rajeshnaroth.medium.com/managing-nested-forms-gracefully-with-formik-a7ed35788653

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */

import { Formik } from 'formik';
import { isEmpty } from 'ramda';
import React from 'react';

const onSubmit = () => {};

export const withSubForm =
  (Component, validationSchema, customProps) =>
  ({ field, form, fieldProps }) => {
    const initialValues = field.value;

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        children={(props) => (
          <Component
            {...props}
            {...customProps}
            setFieldValue={form.setFieldValue}
            setFieldError={form.setFieldError}
            setErrors={form.setErrors}
            name={field.name}
            fieldProps={fieldProps}
          />
        )}
      />
    );
  };

// Anytime the value changes, set the field value
export const useValues = (name, props) => {
  React.useEffect(() => {
    if (!isEmpty(props.errors)) {
      props.setFieldError(name, 'SubFormError');
    }
  }, [name, props.values]);
};
