import { gql } from '@apollo/client';

export const searchEventsQuery = gql`
  query searchEvents($mandatory_presence_contains: String) {
    searchEvents(mandatory_presence_contains: $mandatory_presence_contains) {
      id
      title
      description
      created_by
      last_update_by
      mandatory_presence
      optional_presence
      begin
      end
      created_at
      updated_at
      consultation_id
      patient {
        id
        first_name
        last_name
        email
        phone_number
        emergency_phone_number
        address
      }
    }
  }
`;
