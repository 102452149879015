import { useQuery } from '@apollo/client'; // material
import arrowBack from '@iconify/icons-eva/arrow-back-fill';
import { Icon } from '@iconify/react';
import { Box, Card, Container, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
// components
import { patientsByEnum } from 'graphql/querys/patientsByEnum';
import { patientByDetailQuery } from 'graphql/querys/patientByDetailQuery';
import { toCamelCase } from 'utils/toCamelCase';
import Page from '../../Page';
import SelectUserTableContent from './SelectUserTableContent';
import SelectUserListToolbar from './SelectUserListToolbar';
import { revertFormattedDate, getComparator } from '../../../utils/comparator';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  // THE id here is used to control the sort value, it has to correspond to the value to be compared
  { id: 'firstName', label: 'Nombre', alignRight: false },
  { id: 'cedula', label: 'Cédula', alignRight: false },
  { id: 'email', label: 'Correo', alignRight: false },
  { id: 'dateOfBirth', label: 'Fecha de nacimiento', alignRight: false },
  { id: 'sex', label: 'Género', alignRight: false }
  // { id: '' }
];

// TODO: refactor to utils/comparator.js
function descendingComparator(a, b, orderBy) {
  const checkDateA = revertFormattedDate(a[orderBy]);
  const checkDateB = revertFormattedDate(b[orderBy]);
  if (checkDateA !== -1 && checkDateB !== -1) {
    // This means that a and b are dates
    if (checkDateB.getTime() < checkDateA.getTime()) {
      console.log('-1');
      return -1;
    }
    if (checkDateB.getTime() > checkDateA.getTime()) {
      console.log('1');
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    console.log('-1');
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    console.log('1');
    return 1;
  }
  return 0;
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1]; // this allows us to keep original order in case of similar order values 'A LITTLE OVERKILL'
  });

  // Pass query as parameter for front side search
  // if (query) {
  //   return filter(
  //     array,
  //     (_user) =>
  //       `${_user.firstName.toLowerCase()} ${_user.lastName.toLowerCase()}`.indexOf(
  //         query.toLowerCase()
  //       ) !== -1
  //   );
  // }
  return stabilizedThis.map((el) => el[0]);
}

export default function SelectUser(props) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('lastName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  if (rowsPerPage === -1) {
    setRowsPerPage(100);
  }

  const {
    data: pData,
    loading: patientLoading,
    error: patientError
  } = useQuery(patientByDetailQuery, {
    variables: { detail: filterName },
    fetchPolicy: 'network-only' // Doesn't check cache before making a network request
  });

  const {
    data: idleData,
    loading: idleLoading,
    error: idleError
  } = useQuery(patientsByEnum, {
    variables: { number: rowsPerPage }
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = patientData.map((n) => n.id);
      console.log(patientData);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    console.log(newSelected);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const loading = patientLoading || idleLoading;
  if (patientError) return <p>ERROR</p>;

  let patientData = [];
  let emptyRows = 0;
  let filteredUsers = [];
  let isUserNotFound = false;
  if (!loading) {
    if (pData.patientByDetail.length !== 0) {
      patientData = toCamelCase(pData.patientByDetail);
    } else {
      patientData = toCamelCase(idleData.getPatientsEnumerated);
    }
    // This empty rows value sets in when in a page > 0, there are not enough entries to fill the value per page
    emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - patientData.length) : 0;
    filteredUsers = applySortFilter(patientData, getComparator(order, orderBy));
    isUserNotFound = filteredUsers.length === 0;
  }

  return (
    <Page title="Dental by Aronin">
      <Container>
        <Stack direction="row" alignItems="center" pt={5} justifyContent="space-between" mb={5}>
          <Tooltip title="Regresar">
            <IconButton onClick={props.toClose}>
              <Icon icon={arrowBack} />
            </IconButton>
          </Tooltip>
        </Stack>

        <Card sx={{ boxShadow: 3 }}>
          <Box pl={3}>
            <Stack direction="row" alignItems="center" pt={5} justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Seleccionar paciente para la consulta
              </Typography>
            </Stack>
          </Box>
          <SelectUserListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder="Buscar paciente..."
          />

          <SelectUserTableContent
            filterName={filterName}
            handleFilterByName={handleFilterByName}
            handleRequestSort={handleRequestSort}
            handleSelectAllClick={handleSelectAllClick}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            order={order}
            orderBy={orderBy}
            TABLE_HEAD={TABLE_HEAD}
            filteredUsers={filteredUsers}
            page={page}
            rowsPerPage={rowsPerPage}
            emptyRows={emptyRows}
            isUserNotFound={isUserNotFound}
            handleClick={handleClick}
          />
        </Card>
      </Container>
    </Page>
  );
}
