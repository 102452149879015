import { useQuery } from '@apollo/client';
import { Box, Card, Skeleton, Stack, Typography } from '@mui/material';
import { filter } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { fromUTCdatetimeToLocalDate } from 'utils/formatTime';
import { paymentByPatientId } from '../../graphql/querys/paymentByPatientIdQuery';
import { toCamelCase } from '../../utils/toCamelCase';
import PaymentListToolbar from './PaymentListToolbar';
import PaymentTableContent from './PaymentTableContent';
import { revertFormattedDate, getComparator } from '../../utils/comparator';

const TABLE_HEAD = [
  // THE id here is used to control the sort value, it has to correspond to the value to be compared
  { id: 'reference', label: 'Referencia', alignRight: false },
  { id: 'paymentDate', label: 'Fecha del pago', alignRight: false },
  { id: 'paymentMethod', label: 'Metodo de pago', alignRight: false },
  { id: 'payedAmount', label: 'Monto del pago', alignRight: false },
  { id: 'consultationId', label: 'Descripcion de la consulta', alignRight: false }
];

// TODO: refactor to utils/comparator.js
function descendingComparator(a, b, orderBy) {
  const checkDateA = revertFormattedDate(a[orderBy]);
  const checkDateB = revertFormattedDate(b[orderBy]);
  if (checkDateA !== -1 && checkDateB !== -1) {
    // This means that a and b are dates
    if (checkDateB.getTime() < checkDateA.getTime()) {
      console.log('-1');
      return -1;
    }
    if (checkDateB.getTime() > checkDateA.getTime()) {
      console.log('1');
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    console.log('-1');
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    console.log('1');
    return 1;
  }
  return 0;
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1]; // this allows us to keep original order in case of similar order values 'A LITTLE OVERKILL'
  });
  //  Pass query as parameter for front side search
  if (query) {
    return filter(array, (_payment) => {
      console.log(_payment, 'payment');
      if (_payment.reference && _payment.consultationDescription) {
        return (
          `${_payment.reference.toLowerCase()}`.indexOf(query.toLowerCase()) !== -1 ||
          `${fromUTCdatetimeToLocalDate(
            _payment.event.begin
          ).toLowerCase()} ${_payment.consultationDescription.toLowerCase()}`.indexOf(
            query.toLowerCase()
          ) !== -1 ||
          `${_payment.paymentDate}`.indexOf(query) !== -1
        );
      }
      if (_payment.reference) {
        return (
          `${_payment.reference.toLowerCase()}`.indexOf(query.toLowerCase()) !== -1 ||
          `${_payment.paymentDate}`.indexOf(query) !== -1
        );
      }
      if (_payment.consultationDescription) {
        return (
          `${_payment.consultationDescription.toLowerCase()}`.indexOf(query.toLowerCase()) !== -1 ||
          `${_payment.paymentDate}`.indexOf(query) !== -1
        );
      }
      return `${_payment.paymentDate}`.indexOf(query) !== -1;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

function formattedDate(d = new Date()) {
  return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('/');
}

export function Payments(props) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('lastName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { id } = useParams();
  const {
    data: paymentData,
    loading: paymentLoading,
    error: paymentError
  } = useQuery(paymentByPatientId, {
    variables: { paymentByPatientIdId: id }
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  if (paymentLoading || !paymentData) {
    return <Skeleton animation="wave" />;
  }

  const payments = toCamelCase(paymentData.paymentByPatientId);
  payments.map(
    (payments) => (payments.paymentDate = formattedDate(new Date(Number(payments.paymentDate))))
  );
  payments.map(
    (payments) =>
      (payments.consultationDate = formattedDate(new Date(Number(payments.consultationDate))))
  );
  console.log(payments);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - payments.length) : 0;

  const filteredPayments = applySortFilter(payments, getComparator(order, orderBy), filterName);

  console.log(filteredPayments);
  const isPaymentNotFound = filteredPayments.length === 0;
  return (
    <>
      <Box px={{ md: 5 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mx={5} my={5}>
          <Typography variant="h4" gutterBottom>
            Pagos
          </Typography>
        </Stack>
        <Card sx={{ boxShadow: 3 }}>
          <PaymentListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder="Buscar pagos..."
            toClose={props.toClose}
          />
          <PaymentTableContent
            isDeletedOnConsultation={false}
            selected={selected}
            filterName={filterName}
            handleFilterByName={handleFilterByName}
            handleRequestSort={handleRequestSort}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            order={order}
            orderBy={orderBy}
            TABLE_HEAD={TABLE_HEAD}
            filteredPayments={filteredPayments}
            page={page}
            rowsPerPage={rowsPerPage}
            emptyRows={emptyRows}
            isPaymentNotFound={isPaymentNotFound}
          />
        </Card>
      </Box>
    </>
  );
}
