import React, { useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Auth } from 'aws-amplify';

const AuthContext = React.createContext();
const UpdateAuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function useAuthUpdate() {
  return useContext(UpdateAuthContext);
}

export function AuthProvider({ children }) {
  const updateAuth = (bool) => localStorage.setItem('token', bool);

  const ifAuthTrue = () => {
    const isTokenPresent = localStorage.getItem('token') === 'true';
    if (isTokenPresent) {
      Auth.currentAuthenticatedUser().then((user) => {
        Sentry.setContext('session', user.attributes);
      });
    }
    return isTokenPresent;
  };

  return (
    <AuthContext.Provider value={ifAuthTrue}>
      <UpdateAuthContext.Provider value={updateAuth}>{children}</UpdateAuthContext.Provider>
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node
};
