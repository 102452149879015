import { gql } from '@apollo/client';

export const patientDataFilesQuery = gql`
  query getPatientDataFiles($getPatientDataFilesId: ID!) {
    getPatientDataFiles(id: $getPatientDataFilesId) {
      name
      mime_type
      date
      key
    }
  }
`;
