import { gql } from '@apollo/client';

export const deleteMultiplePaymentsByIdOnConsultationMutation = gql`
  mutation deleteMultiplePaymentsByIdOnConsultation($paymentsData: DeleteMultiplePaymentInput!) {
    deleteMultiplePaymentsByIdOnConsultation(paymentsData: $paymentsData) {
      paymentData
      paymentError {
        field
        message
        totalDebt
      }
    }
  }
`;
