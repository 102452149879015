import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Alert,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  Box,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useAuthUpdate, useAuth } from '../../../AuthContext';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const updateAuth = useAuthUpdate();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [signInError, setSignInError] = useState('');

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Por favor, ingrese un correo electrónico válido')
      .required('Por favor, ingrese un correo electrónico'),
    password: Yup.string().required('Por favor, ingrese una contraseña')
  });

  const AuthValue = useAuth();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (data) => {
      setSignInError('');
      try {
        await Auth.signIn(data.email, data.password);
        updateAuth(true);
        navigate('/dashboard/user', { replace: true });
        // @ts-ignore
        window.dataLayer.push({ event: 'user_login', user: data.email });
      } catch (error) {
        if (error.name === 'NotAuthorizedException') {
          setSignInError('Su correo electrónico o contraseña son incorrectos');
        } else {
          setSignInError('Ha ocurrido un error, por favor intente nuevamente o contactenos');
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    updateAuth(false);
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Correo electrónico"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Contraseña"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        {signInError ? (
          <Box py={3}>
            <Alert severity="error">{signInError}</Alert>
          </Box>
        ) : null}

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Recuérdame"
          />

          <Link component={RouterLink} variant="subtitle2" to="/forgotPassword">
            ¿Olvidaste tu contraseña?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Iniciar sesión
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
