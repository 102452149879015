import { Card, Container, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';

import { MHidden } from '../components/@material-extend';
import ForgotPasswordForm from '../components/authentication/login/ForgotPasswordForm';
import SendRecoveryCodeForm from '../components/authentication/login/SendRecoveryCodeForm';
import Page from '../components/Page';
import AuthLayout from '../layouts/AuthLayout';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function ForgotPassword() {
  const [recoveryCodeSent, setRecoveryCodeSent] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const onRecoveryCodeSent = () => {
    setRecoveryCodeSent(true);
  };

  const onUserEmailInsert = (email) => {
    setUserEmail(email);
  };

  return (
    <RootStyle title="Dental by Aronin">
      <AuthLayout>
        ¿No tienes una cuenta? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Regístrate
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }} />
          {/* Hi, Welcome Back
          </Typography> */}
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          {!recoveryCodeSent ? (
            <>
              <Stack sx={{ mb: 5 }}>
                <Typography variant="h4" gutterBottom>
                  ¿Olvidaste tu contraseña?
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Se ha enviado un correo electrónico de <b>verificationemail.com</b>, recuerda
                  revisar tu correo no deseado
                </Typography>
              </Stack>

              <SendRecoveryCodeForm
                onRecoveryCodeSent={onRecoveryCodeSent}
                onUserEmailInsert={onUserEmailInsert}
              />
            </>
          ) : (
            <>
              <Stack sx={{ mb: 5 }}>
                <Typography variant="h4" gutterBottom>
                  ¿Olvidaste tu contraseña?
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Se ha enviado un correo electrónico de <b>verificationemail.com</b>, recuerda
                  revisar tu correo no deseado
                </Typography>
              </Stack>
              <ForgotPasswordForm userEmail={userEmail} />
            </>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
