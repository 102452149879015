// @ts-nocheck
import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Card, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatDateWithoutHour } from 'utils/formatDateWithoutHour';
import { UserForm } from '../components/_dashboard/user/UserForm';
import { insertPatientandMedicalHistoryMutation } from '../graphql/mutations/insertPatientAndMedicalHistory';
import { patientsCount } from '../graphql/querys/patientsCount';

import { generatePatientDataUploadURL } from '../graphql/querys/generatePatientDataUploadURLQuery';
import { generateSignatureDataUploadURL } from '../graphql/querys/generateSignatureDataUploadUrlQuery';
import { patientDataFilesQuery } from '../graphql/querys/patientDataFilesQuery';
import { patientsByEnum } from '../graphql/querys/patientsByEnum';
import { signatureDataFilesQuery } from '../graphql/querys/signatureDataFilesQuery';
import {
  getMedicalHistoryDataFromCreatePatientMutation,
  getPatientDataFromCreatePatientMutation
} from '../utils/propertyShorthand';
import { putS3 } from '../utils/s3Actions';
import { toSnakeCase } from '../utils/toSnakeCase';

export default function CreatePatient() {
  const navigate = useNavigate();

  const [
    insertPatientAndMedicalHistory,
    {
      data: PatientAndMedicalHistoryData,
      loading: PatientAndMedicalHistoryLoading,
      error: PatientAndMedicalHistoryError
    }
  ] = useMutation(insertPatientandMedicalHistoryMutation, {
    // Then re-run
    refetchQueries: [{ query: patientsByEnum, variables: { number: 5 } }, { query: patientsCount }]
  });

  const [generatePatientDataUploadURLQuery, { error, data: fileData, loading: fileLoading }] =
    useLazyQuery(generatePatientDataUploadURL);

  const [
    generateSignatureDataUploadURLQuery,
    { error: signatureError, data: filesignatureData, loading: filesignatureLoading }
  ] = useLazyQuery(generateSignatureDataUploadURL);

  const [getPatientsDataFiles, { refetch: refetchPatientsDataFiles }] =
    useLazyQuery(patientDataFilesQuery);

  const [getPatientsSignatureFiles, { refetch: refetchSignatureData }] =
    useLazyQuery(signatureDataFilesQuery);

  const onSubmit = async (values, files, signatureFiles) => {
    values.dateOfBirth = values.dateOfBirth ? formatDateWithoutHour(values.dateOfBirth) : null;
    const patientData = toSnakeCase(getPatientDataFromCreatePatientMutation(values));
    const insertPatientData = { patientData: { ...patientData } };
    const medicalHistoryData = toSnakeCase(getMedicalHistoryDataFromCreatePatientMutation(values));
    const insertMedicalHistoryData = { medicalHistoryData: { ...medicalHistoryData } };

    const response = await insertPatientAndMedicalHistory({
      variables: { ...insertPatientData, ...insertMedicalHistoryData }
    });

    // Send files to s3
    const urlData = files;
    const fileData = files.map((a) => a.file);
    urlData.map((file) => delete file.file);

    const urlInput = toSnakeCase(urlData);
    console.log('this is response', response);
    const urls = await generatePatientDataUploadURLQuery({
      variables: {
        generatePatientDataUploadUrlId: response.data.insertPatientAndMedicalHistory.patientData.id,
        arrayMetadata: urlInput
      }
    });
    const putResponse = putS3(fileData, urls.data.generatePatientDataUploadURL);

    // Send signature files to s3
    const url = await generateSignatureDataUploadURLQuery({
      variables: {
        patientId: response.data.insertPatientAndMedicalHistory.patientData.id
      }
    });

    const putSignatureResponse = await putS3(signatureFiles, [
      url.data.generateSignatureDataUploadURL
    ]);

    const patientDataFiles = await getPatientsDataFiles({
      variables: {
        getPatientDataFilesId: response.data.insertPatientAndMedicalHistory.patientData.id
      }
    });

    const SignatureDataFiles = await getPatientsSignatureFiles({
      variables: {
        patientId: response.data.insertPatientAndMedicalHistory.patientData.id
      }
    });

    if (!PatientAndMedicalHistoryError) {
      navigate('/dashboard/user', { replace: true });
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} ml={5}>
        <Typography variant="h4" gutterBottom>
          Crear paciente
        </Typography>
      </Stack>
      <Box p="30px">
        <Card sx={{ boxShadow: 3 }}>
          <Box px={5} pb={3}>
            <UserForm onSubmit={onSubmit} />
          </Box>
        </Card>
      </Box>
    </>
  );
}
