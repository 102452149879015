import { Card, Container, Stack, Typography } from '@mui/material';
// components
import Page from 'components/Page';
import UserListTable from 'components/manager/UserListTable';

export default function Manager() {
  return (
    <Page title="Dental by Aronin">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://us-east-2.console.aws.amazon.com/cognito/v2/idp/user-pools/${process.env.REACT_APP_COGNITO_USERPOOL_ID}/users?region=${process.env.REACT_APP_COGNITO_REGION}`}
          >
            View in AWS (new tab)
          </a>
        </Stack>
        <Card sx={{ boxShadow: 3 }}>
          <UserListTable actionTitle={undefined} actionHandler={undefined} />
        </Card>
      </Container>
    </Page>
  );
}
