export const getPatientDataFromCreatePatientMutation = ({
  firstName,
  lastName,
  dateOfBirth,
  sex,
  placeOfBirth,
  cedula,
  email,
  phoneNumber,
  emergencyPhoneNumber,
  ocupation,
  address
}) => ({
  firstName,
  lastName,
  dateOfBirth,
  sex,
  placeOfBirth,
  cedula,
  email,
  phoneNumber,
  emergencyPhoneNumber,
  ocupation,
  address
});

export const getMedicalHistoryDataFromCreatePatientMutation = ({
  medicalAntecedents,
  odontodiagram,
  notes,
  attachedFiles,
  familyAntecedents,
  oralHistory,
  allergies,
  habits
}) => ({
  medicalAntecedents,
  odontodiagram,
  notes,
  attachedFiles,
  familyAntecedents,
  oralHistory,
  allergies,
  habits
});
