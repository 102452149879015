import React from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';

export function UserDetailHeader(props) {
  const p = props.patient;
  console.log(p);
  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardHeader title="Paciente" titleTypographyProps={{ variant: 'h3' }} />
      <CardContent>
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <Grid container spacing={2} columns={{ xs: 6, sm: 12 }}>
            <Grid xs={6} sm={6} md={2}>
              Nombre
              <Typography variant="h6">{p.first_name}</Typography>
            </Grid>
            <Grid xs={6} sm={6} md={2}>
              Apellido
              <Typography variant="h6">{p.last_name}</Typography>
            </Grid>
            <Grid xs={12} sm={6} md={2}>
              Email
              <Typography variant="h6" style={{ overflowWrap: 'anywhere' }}>
                {p.email}
              </Typography>
            </Grid>
            <Grid xs={12} sm={6} md={2}>
              Cédula
              <Typography variant="h6" style={{ overflowWrap: 'anywhere' }}>
                {p.cedula}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
