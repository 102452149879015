import { useMutation, useQuery } from '@apollo/client';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Alert, Button, Card, Skeleton, Stack, Typography } from '@mui/material';
import { filter } from 'lodash';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { getPropertyByPath } from 'utils/getPropertyByPath';
import { ConsultationsOwedByPatientId } from 'graphql/querys/consultationsOwedByPatientIdQuery';
import { applySortFilter, getComparator } from 'utils/comparator';
import { ConsultationsByPatientId } from '../../../graphql/querys/consultationsByPatientIdQuery';
import { toCamelCase } from '../../../utils/toCamelCase';
import ConsultationToolbar from './ConsultationToolbar';
import ConsultationTableContent from './ConsultationTableContent';

const TABLE_HEAD = [
  // THE id here is used to control the sort value, it has to correspond to the value to be compared
  { id: 'event.begin', label: 'Fecha', alignRight: false },
  { id: 'consultationDescription', label: 'Motivo de la consulta', alignRight: false },
  { id: 'event.state', label: 'Estado', alignRight: false }
];

function formattedDate(d = new Date()) {
  return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('/');
}

export function Consultations(props) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('event.begin');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { id } = useParams();
  const {
    data: pData,
    loading: consultationsLoading,
    error: consultationsError
  } = useQuery(ConsultationsByPatientId, {
    variables: { consultationsByPatientIdId: id }
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  if (consultationsLoading || !pData) {
    return <Skeleton variant="rectangular" height={200} />;
  }

  const consultations = toCamelCase(pData.consultationsByPatientId);
  consultations.map(
    (consultation) => (consultation.event.begin = formattedDate(new Date(consultation.event.begin)))
  );

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - consultations.length) : 0;

  const filteredConsultations = applySortFilter(
    consultations,
    getComparator(order, orderBy),
    filterName
  );

  const isConsultationNotFound = filteredConsultations.length === 0;
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Consultas
        </Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to={`/dashboard/createConsultation/${id}`}
          startIcon={<Icon icon={plusFill} />}
        >
          Nueva consulta
        </Button>
      </Stack>
      <Card sx={{ boxShadow: 3 }}>
        <ConsultationToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
          placeholder="Buscar consulta..."
        />

        <ConsultationTableContent
          filterName={filterName}
          handleRequestSort={handleRequestSort}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          order={order}
          orderBy={orderBy}
          TABLE_HEAD={TABLE_HEAD}
          filteredConsultations={filteredConsultations}
          page={page}
          rowsPerPage={rowsPerPage}
          emptyRows={emptyRows}
          isConsultationNotFound={isConsultationNotFound}
        />
      </Card>
    </>
  );
}
