import { gql } from '@apollo/client';

export const paymentByConsultationId = gql`
  query paymentByConsultationId($paymentByConsultationIdId: ID!) {
    paymentByConsultationId(id: $paymentByConsultationIdId) {
      payment_id
      patient_id
      reference
      consultation_id
      payment_date
      payment_method
      payed_amount
      event {
        begin
      }
      consultation_description
    }
  }
`;
