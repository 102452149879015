import { useMutation, useQuery } from '@apollo/client'; // material
import { Button, Card, Container, Stack, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
// components
import { specificGroupUsersQuery } from 'graphql/querys/specificGroupUsers';
import Page from 'components/Page';
import { createGroupMutation } from 'graphql/mutations/createGroup';
import { allGroupsQuery } from 'graphql/querys/allGroups';
import { useState } from 'react';

// ----------------------------------------------------------------------

export default function CreateGroup() {
  const [value, setValue] = useState('');

  const [
    createGroup,
    { data: createGroupData, loading: createGroupLoading, error: createGroupError }
  ] = useMutation(createGroupMutation, {
    // Then re-run
    refetchQueries: [{ query: allGroupsQuery }]
  });

  const validateGroupName = (GroupName) => !!GroupName.match(/^[a-zA-Z]+$/);

  const handleCreateGroup = () => {
    createGroup({
      variables: {
        GroupName: value
      }
    });
  };

  const handleChange = (e) => setValue(e.target.value);
  const isInvalid = value !== '' && !validateGroupName(value);
  return (
    <Page title="Dental by Aronin">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create group
          </Typography>
        </Stack>
        <Card sx={{ boxShadow: 3, padding: 5 }}>
          <TextField
            error={isInvalid}
            label="Group Name"
            helperText={isInvalid && <p>Group name must only contain letters!</p>}
            onChange={handleChange}
          />
          <br />
          <br />
          <Button
            variant="contained"
            disabled={value.length < 3 || !validateGroupName(value)}
            onClick={handleCreateGroup}
          >
            Create
          </Button>
          {createGroupData && !createGroupError && <p>Group has been created!</p>}
          {createGroupError && <p>Group creation failed : {createGroupError.message}</p>}
        </Card>
      </Container>
    </Page>
  );
}
