import { gql } from '@apollo/client';

export const generateConsultationDataUploadURL = gql`
  query generateConsultationDataUploadURL(
    $consultationId: ID!
    $arrayMetadata: [FileMetadataInput]!
  ) {
    generateConsultationDataUploadURL(
      consultationId: $consultationId
      arrayMetadata: $arrayMetadata
    )
  }
`;
