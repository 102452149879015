import { gql } from '@apollo/client';

export const ConsultationsOwedByPatientId = gql`
  query ConsultationsOwedByPatientId($id: ID!) {
    consultationsOwedByPatientId(id: $id) {
      consultation_description
      total_amount
      amount_to_pay
      payed_amount
      patient_id
      event {
        begin
        state
      }
      notes
      consultation_id
      doctor
    }
  }
`;
