import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Alert, Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { updatePatientAndMedicalHistoryMutation } from '../../../graphql/mutations/updatePatientAndMedicalHistory';
import { generatePatientDataUploadURL } from '../../../graphql/querys/generatePatientDataUploadURLQuery';
import { generateSignatureDataUploadURL } from '../../../graphql/querys/generateSignatureDataUploadUrlQuery';
import { patientDataFilesQuery } from '../../../graphql/querys/patientDataFilesQuery';
import { patientsByEnum } from '../../../graphql/querys/patientsByEnum';
import { signatureDataFilesQuery } from '../../../graphql/querys/signatureDataFilesQuery';
import {
  getMedicalHistoryDataFromCreatePatientMutation,
  getPatientDataFromCreatePatientMutation
} from '../../../utils/propertyShorthand';
import { putS3 } from '../../../utils/s3Actions';
import { toCamelCase } from '../../../utils/toCamelCase';
import { toSnakeCase } from '../../../utils/toSnakeCase';
import { UserForm } from '../user/UserForm';

export function MedicalHistory() {
  const [confimEdit, setConfirmEdit] = useState(false);
  const [
    updatePatientandMedicalHistory,
    {
      data: PatientAndMedicalHistoryData,
      loading: PatientAndMedicalHistoryLoading,
      error: PatientAndMedicalHistoryError
    }
  ] = useMutation(updatePatientAndMedicalHistoryMutation, {
    // Then re-run
    refetchQueries: [{ query: patientsByEnum, variables: { number: 5 } }]
  });

  const [generatePatientDataUploadURLQuery, { error, data: fileData, loading: fileLoading }] =
    useLazyQuery(generatePatientDataUploadURL);

  const { id } = useParams();

  const { refetch: refetchData, data: patientsFileData } = useQuery(patientDataFilesQuery, {
    variables: { getPatientDataFilesId: id }
  });

  const { refetch: refetchSignatureData, data: patientsSignatureData } = useQuery(
    signatureDataFilesQuery,
    {
      variables: { patientId: id }
    }
  );

  const [
    generateSignatureDataUploadURLQuery,
    { error: signatureError, data: filesignatureData, loading: filesignatureLoading }
  ] = useLazyQuery(generateSignatureDataUploadURL);

  const { data: pData, loading: dataLoading } = useQuery(
    gql`
      query patientDetailByIdQuery($id: ID!) {
        patientById(id: $id) {
          id
          first_name
          last_name
          date_of_birth
          sex
          place_of_birth
          cedula
          email
          phone_number
          emergency_phone_number
          ocupation
          address
        }
        medicalHistoryByPatientId(id: $id) {
          medical_antecedents
          odontodiagram
          notes
          attached_files
          family_antecedents
          oral_history
          allergies
          habits
        }
      }
    `,
    {
      variables: { id }
    }
  );
  if (dataLoading) {
    return <Skeleton />;
  }
  const formValues = toCamelCase({ ...pData.medicalHistoryByPatientId, ...pData.patientById });

  const onSubmit = async (values, files, signatureFiles) => {
    const patientData = toSnakeCase(getPatientDataFromCreatePatientMutation(values));
    const insertPatientData = { patientData: { ...patientData } };
    const medicalHistoryData = toSnakeCase(getMedicalHistoryDataFromCreatePatientMutation(values));
    const insertMedicalHistoryData = { medicalHistoryData: { ...medicalHistoryData } };
    updatePatientandMedicalHistory({
      variables: { ...insertPatientData, ...insertMedicalHistoryData, id }
    });

    const urlData = files;
    const fileData = files.map((a) => a.file);
    urlData.map((file) => delete file.file);

    const urlInput = toSnakeCase(urlData);
    const urls = await generatePatientDataUploadURLQuery({
      variables: {
        generatePatientDataUploadUrlId: id,
        arrayMetadata: urlInput
      }
    });
    console.log(urls.data.generatePatientDataUploadURL);
    const putResponse = await putS3(fileData, urls.data.generatePatientDataUploadURL);
    console.log(putResponse);
    console.log(patientsFileData);
    refetchData();
    console.log(patientsSignatureData);

    // Send signature files to s3
    const url = await generateSignatureDataUploadURLQuery({
      variables: {
        patientId: id
      }
    });

    const putSignatureResponse = await putS3(signatureFiles, [
      url.data.generateSignatureDataUploadURL
    ]);

    refetchSignatureData();

    if (!PatientAndMedicalHistoryError) {
      setConfirmEdit(true);
      setTimeout(() => {
        setConfirmEdit(false);
      }, 4000);
    }
  };
  return (
    <>
      <UserForm values={formValues} onSubmit={onSubmit} />
      {confimEdit && <Alert severity="success">El paciente ha sido editado exitosamente</Alert>}
    </>
  );
}
