import { Icon } from '@iconify/react';
import peopleFill from '@iconify/icons-eva/people-fill';
import calendarFill from '@iconify/icons-eva/calendar-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';

// ----------------------------------------------------------------------

export const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon(pieChart2Fill)
  // },
  {
    title: 'Pacientes',
    path: '/dashboard/user',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Calendario',
    path: '/dashboard/scheduler',
    icon: getIcon(calendarFill)
  },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  {
    title: 'Cuentas por cobrar',
    path: '/dashboard/bills',
    icon: getIcon(fileTextFill)
  }
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
