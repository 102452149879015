import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const Dropzone = (props) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((acceptedFile) => {
        props.setFiles((prevArray) => {
          const typeArray = acceptedFile.type.split('/');
          const type = typeArray[typeArray.length - 1];
          const currentFile = {
            file: acceptedFile,
            name: acceptedFile.name,
            mimeType: type
          };
          if (!prevArray.some((file) => file.name === currentFile.name)) {
            // TODO: maybe use push instead of spread operator
            return [...prevArray, currentFile];
          }
          return prevArray;
        });
      });
    }
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Arrastre o elija un archivo</p>
      </div>
    </div>
  );
};

export default Dropzone;
